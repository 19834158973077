import React from 'react';
import Required from './Required.js'

function Price(props){
  if(props.price){
    return(
      <div className='input-group-prepend bg-white'>
        <span className='input-group-text bg-white'>$</span>
      </div>
    );
  }
  return(null);
}

class InputTextAttribute extends React.Component {

  constructor(props) {
    super(props);
    this.state = {enabled: true};
    this.getValue = this.getValue.bind(this);
    this.changeInputMaxMin = this.changeInputMaxMin.bind(this);
  }

  // Check configurable
  //
  // Check if product type is configurable
  // If it is, hide extra elements
  // @return [String] with class to asign in div
  checkConfigurable = () => {
    return this.props.selectedKindState == 'configurable' ? 'd-none' : ''
  }

  // Name field
  //
  // Check if product type is configurable
  // If it is, changes size of col of name field
  // @return [String] with class to asign in div
  nameField = () => {
    return this.props.selectedKindState == 'configurable' ? 'col-md-12' : 'col-md-8'
  }

  // Reset Invalid Class
  //
  // Reset invalid class in input value change
  // Makes the red field disappear on the input.
  // @param [Object] event that triggered the change, to obtain the target and remove the class
  // @return [Void]
  resetInvalidClass = (event) => {
    event.target.classList.remove('is-invalid');
  }

  componentDidUpdate(){
    if(this.props.value){
      document.getElementById(`${this.props.id}`).value = this.props.value;
    }
    this.changeInputMaxMin();
  }

  componentDidMount(){
    if(this.props.value){
      document.getElementById(`${this.props.id}`).value = this.props.value;
    }
    this.changeInputMaxMin();
  }

  changeInputMaxMin(){
    if(this.props.type === 'number'){
      document.getElementById(`${this.props.id}`).type = this.props.type;
      document.getElementById(`${this.props.id}`).max = this.props.max;
      document.getElementById(`${this.props.id}`).min = this.props.min;
    }else{
      document.getElementById(`${this.props.id}`).type = this.props.type;
      document.getElementById(`${this.props.id}`).removeAttribute('max');
      document.getElementById(`${this.props.id}`).removeAttribute('min');
    }
  }

  getValue(event){
    let {required, calculatePercentOfInput, calcVolume, name, notRequired} = this.props
    if((required&&calculatePercentOfInput)||(notRequired&&calculatePercentOfInput)){
      calculatePercentOfInput()
    }
    if(calcVolume!==undefined){
      calcVolume();
    }
    if(name==='standar_cost'){
      document.getElementsByName('selling_price')[0].min = document.getElementsByName('standar_cost')[0].value
    }
  }
  
  render() {
    let {description, title, required, flexline_name, price, name, id, disabled, readonly, hidden, maxlength, skuAuto, measurement, fromDescription, checked} = this.props
    
    return(
      <div className='row Product-show-InputTextAttribute mb-2'>
        <div className={`col-md-${fromDescription? '12' : '4'}`} data-toggle='tooltip' data-placement='right' title={description}>
          <label className='mb-0 labelb-0' for={id}>
            {title}
            <Required required={required} />
          </label>
          <small className='text-secondary'>
            {flexline_name}
          </small>
        </div>
        <div className={`form-group col-md-${fromDescription? '12' : '8'} ${this.nameField()}`}>
          <div className='input-group shadow-none'>
            <Price price={price}/>
            <input type='text' name={name} className={`${this.props.type != 'checkbox' ? 'form-control' : ''} text-uppercase`} id={id} 
            placeholder={title} disabled={disabled} onKeyUp={e => this.getValue(e)} onChange={ e => this.resetInvalidClass(e) } required={required}
            readOnly={readonly} hidden={hidden} maxLength={maxlength} skuauto={skuAuto}
            step={measurement? '.01' : ''} checked={checked}/>
          </div>
        </div>
      </div>
    );
  }
}
export default InputTextAttribute;