import React from 'react';
import TitleButton from '../../components/TitleButtonEdit';
// import { toast } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalCheck from '../modal_check/ModalCheck';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' 
// import './RelatedProducts.css';

class TechnicalProducts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: true,
      modalcheck: false,
      modalcheckData: [],
      sku: '',
      technical_products: []
    };
    this.inputEnabler = this.inputEnabler.bind(this);
    this.check_data = this.check_data.bind(this);
    this.changeStateModalChech = this.changeStateModalChech.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  inputEnabler(){
    this.setState({enabled: !this.state.enabled});
  }

  componentWillReceiveProps(){
    this.setState({enabled: true});
    this.setState({technical_products: this.props.technical_products})
  }

  changeStateModalChech(){
    this.setState({modalcheck: !this.state.modalcheck});
  }

  check_data(){
    let array = [];
    let sku = document.getElementById('input_sku').value;
    let name = 'technical_products';
    let value = this.state.technical_products;
    let nextState = {};
    let nextAttributes = {};

    nextState['type'] = 'product_change';
    nextState['id'] = sku;
    nextAttributes['sku'] = sku;
    nextAttributes['field_name'] = name;
    nextAttributes['field_detail'] = '';
    nextAttributes['old_value'] = this.props.technical_products;
    nextAttributes['new_value'] = value;
    nextAttributes['title'] = 'Tapacantos Relacionados';
    nextAttributes['updated_by'] = localStorage.getItem('email');

    nextState['attributes'] = nextAttributes;
    array.push(nextState);

    if(array.length!==0){
      this.setState({modalcheck: !this.state.modalcheck});
      this.setState({modalcheckData: array});
      this.setState({sku: sku});
    }else{
      toast.warn('No se ha realizado ningun cambio');
    }
  }

  handleChange(tags) {
    this.setState({technical_products: tags})
  }


  render() {
    let {description, title,  flexline_name, price, name, id, disabled, readonly, hidden, maxlength, skuAuto, measurement, fromDescription} = this.props
    return(

      <div className='row Product-show-InputTextAttribute'>
        <div className={`col-md-2`} data-toggle='tooltip' data-placement='right' title={description}>
          <p className='mb-0 pb-0'>
            {title}
          </p>
          <small className='text-secondary'>
            {flexline_name}
          </small>
        </div>
        <div className={`form-group mb-0 col-md-10`}>
          <TagsInput id={id} inputProps={{placeholder:'Agrega el Producto Tecnico relacionado. Ingresa el sku y presiona [ENTER] para agregarlo'}} 
          value={this.state.technical_products}
          onChange={this.handleChange} disabled={disabled} removeKeys={[7]}/>
          <div className='form-group' hidden={true}>
            <div className='input-group'>
              <input id='related-products-sku' name={name} type='text' placeholder={title}
              className='form-control' value={this.state.technical_products}></input>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TechnicalProducts;
