import React from 'react';
import ProductList from '../product_list/ProductList'
import { Link } from 'react-router-dom';
import { getPendingChanges } from '../../api/APIGet'
import Pagination from "@material-ui/lab/Pagination";
// import { data } from './data.json'
// import './Index.css';

class Index extends React.Component {

  constructor() {
    super();
    this.state = {
      selectedId: null,
      error: false,
      totalPages: 10,
      current_page: 1,
    }
    this.getProductsData = this.getProductsData.bind(this);
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount(){
    this.getProductsData(this.state.current_page);
  }

  handleChange = (event, value) => {
    this.setState({current_page: value})
    this.getProductsData(value);
  }

  getProductsData(page){
    getPendingChanges(page).then( response => {
      if(response!==undefined){
        this.setState({ data: response.data})
        if(response.meta!==undefined){
          
          this.setState({
            current_page: page,
            totalPages: response.meta.max_pages,
          })
        }
      }else{
        this.setState({ error: true })
      }
    });
  }

  render() {

    if(this.state.data===undefined || this.state.data===null){
      if(this.state.error){
        return(
          <div className='row'>
            <div className='col-md-12 mt-5 text-center'>
              <h1 className='text-primary'>Acceso no permitido, Pregunta a un supervisor</h1>
            </div>
          </div>
        )
      }else{
        return <h2 className='col-12'>Cargando...</h2>;
      }
    }

    return(
      <div className='row'>
        <hr className='col-md-12'/>
        <div className='col-md-4'>
          <h3 className='ml-2'>CAMBIOS PENDIENTES</h3>
        </div>
        <div className='col-md-4'>
          <Pagination count={this.state.totalPages} page={this.state.current_page} onChange={this.handleChange} color="primary" className="justify-content-center"/>
        </div>
        <div className='col-md-4 text-right'>
          <Link className='btn btn-primary' to='/productchanges/all'>Cambios realizados</Link>
        </div>
        
        <div className='col-md-12 mt-3'>
          <ProductList data={this.state.data} getProductsData={this.getProductsData} pending_change={true}/>
        </div>
      </div>
    );
  }
}

export default Index;