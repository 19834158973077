import React from 'react';
import ProductItem from './ProductItem'

function PendingChange(props){
  if(props.pending_change){
    return(
      <th scope='col'></th>
    )
  }else{
    return null
  }
}

class ProductList extends React.Component {
  render() {
    // var array_sku = this.props.data.map((product, i) => array_sku.push(product.id));
    // var uniqueSku = Array.from(new Set(array_sku));

    let card_todos = this.props.data.map((product, i) => {
      return(
        <ProductItem product={product} key={i} getProductsData={this.props.getProductsData}
        pending_change={this.props.pending_change}/>
      )
    });

    return(
      <div className='table-responsive'>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope='col'>Sku</th>
              <th scope='col'>Tipo</th>
              <th scope='col'>Elemento</th>
              <th scope='col'>Solicita</th>
              <th scope='col'>Valor Inicial</th>
              <th scope='col'>Valor nuevo</th>
              <PendingChange pending_change={this.props.pending_change} />
              <PendingChange pending_change={this.props.pending_change} />
              <th scope='col'>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {card_todos}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ProductList;
