import React, {useState, useEffect} from 'react';
import './published.css'
import CheckBoxPublish from '../../components/CheckBoxPublish';
import PublishSegment from '../../components/PublishSegment';
// import './Name.css';

const Published = ({status, publish_segment,barPercentage,...props}) => {
  const [hideButton, setHideButton] = useState(true)
  const [currentRole, setCurrentRole] = useState("");

  const segments = [{id: 'B2B', type: 'B2B'},
                    {id: 'B2C', type: 'B2C'},
                    {id: 'B2B-B2C', type: 'B2B y B2C'}]

  useEffect(() => {
    
    setCurrentRole(localStorage.getItem('current_role'))
  }, [barPercentage])

  useEffect(() => {
    setHideButton(true)
    loadData()
  }, [props])

  const checkRole = (role) => {
    return role == currentRole
  }

  // Load Data
  // On commponent load, retrieves data from the document and the products
  // And sets initial values for the published section in product detial
  // 
  // @return [Void] Set initial values.
  const loadData = () =>{
    setHideButton(barPercentage !== 100)
    let btnPublish = document.getElementById('publish')
    let btnPublishText = document.getElementById('publish-text')
    let published_b2b = document.getElementById('B2B')
    let published_b2c = document.getElementById('B2C')
    let published_b2b_b2c = document.getElementById('B2B-B2C')
  
    btnPublish.checked = status === 'published'
    btnPublishText.innerText = status === 'published' ?  'PUBLICADO' : 'PUBLICABLE' 
    if(publish_segment.includes('B2B') && publish_segment.includes('B2C')){
      published_b2b_b2c.checked = true
    }
    else if(publish_segment.includes('B2B')){
      published_b2b.checked = true
    }
    else if(publish_segment.includes('B2C')){
      published_b2c.checked = true
    }
    else{
      published_b2b = false
      published_b2c = false
      published_b2b_b2c = false
    }
  }

  let roleClass =  (checkRole('basic') || checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? '' : 'd-none'

  return(
    <div id='container_published' className={`${roleClass}`} name='form_change_name' hidden={hideButton}>
      <div className='row'>
        <ul className="ks-cboxtags pl-2">
          <CheckBoxPublish id='publish' type='PUBLICABLE' currentRole={currentRole}/>
          <PublishSegment segments = { segments } currentRole={currentRole} />
        </ul>
      </div>
    </div>
  );
}
export default Published;
