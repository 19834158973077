import React, {useState, useEffect} from 'react';
import es from "../../../es.json"
import SelectAtribute from '../../components/SelectAttribute';
import InputTextAttribute from '../../components/InputTextAttribute';
import RadioButton from '../../components/RadioButton'
import TitleButton from '../../components/TitleButtonEdit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalCheck from '../modal_check/ModalCheck';
import { getCountries, getFamiliesData, getSubFamiliesData, getCategories, getBrandsData, getManagersData, barcodeAvailable } from '../../../api/APIGet'
import { unit } from '../../../api/unit.json'
import { sell } from '../../../api/sell.json'
import SwitchRadioButton from '../../components/SwitchRadioButton';
import { connect } from 'react-redux'

function Attributes(props) {

  const [enabled, setEnabled] = useState(true)
  const [modalCheck, setModalCheck] = useState(false)
  const [modalCheckData, setModalCheckData] = useState([])
  const [countries, setCountries] = useState([])
  const [families, setFamilies] = useState([])
  const [subfamilies, setSubfamilies] = useState([])
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [managers, setManagers] = useState([])

  const inputEnabler = () => {
    setEnabled(!enabled)
    if(!enabled){
      setTimeout( () => {
        props.calculatePercentOfInput()
      }, 1000);
    }
  }

  useEffect(() => {
    setEnabled(true);
  }, [props])

  useEffect(() => {
    loadDropdownData()
  },[])

  // Check Barcode
  // Calls the endpoint to check if the barcode is already in use
  // If the barcode is available, proceed to check the SKU
  // If the barcode is not available, show warning message and focus the barcode field
  // Puts a placeholder in button to show the user that the process is running
  //
  // @returns [Void] Makes differents calls depending on condition.
  const checkBarcode = () => {
    let barcode_field = document.getElementById("bar_code");
    barcodeAvailable(barcode_field.value).then((response) => {
      if((response != 'ok') && (barcode_field.value !== props.barcode)){
        toast.warn(response)
        barcode_field.classList.add('is-invalid');
        props.calculatePercentOfInput()
      }
      else{
        barcode_field.classList.remove('is-invalid');
        check_data()
      }
    })
  }

  const check_data = () => {
    let array = [];
    document.querySelectorAll('.attributes select, .attributes input[type=text]').forEach((select) => {
      let name = select.getAttribute('name');
      let value = select.value.toUpperCase().trim();
      
      if(value !== props[name].trim()){
        
        if(select.getAttribute('duplicate')!==null){
          let duplicate = {};
          let nextduplicate = {};
  
          duplicate['type'] = 'product_change';
          duplicate['id'] = props.sku;
          nextduplicate['sku'] = props.sku;
          nextduplicate['field_name'] = select.getAttribute('duplicate');
          nextduplicate['field_detail'] = '';
          nextduplicate['old_value'] = props[name].trim();
          nextduplicate['new_value'] = value;
          nextduplicate['title'] = null;
          nextduplicate['updated_by'] = localStorage.getItem('email');
  
          duplicate['attributes'] = nextduplicate;
          array.push(duplicate);
        }

        let nextState = {};
        let nextAttributes = {};

        nextState['type'] = 'product_change';
        nextState['id'] = props.sku;
        nextAttributes['sku'] = props.sku;
        nextAttributes['field_name'] = name;
        nextAttributes['field_detail'] = '';
        nextAttributes['old_value'] = props[name].trim();
        nextAttributes['new_value'] = value;
        nextAttributes['title'] = select.getAttribute('placeholder');
        nextAttributes['updated_by'] = localStorage.getItem('email');

        nextState['attributes'] = nextAttributes;
        array.push(nextState);
      }
    })
    // of querySelectorAll was removed #radio_EXISTENCIA,
    document.querySelectorAll('#radio_NACIONAL, #radio_A').forEach((element) => {
      var radio = document.getElementsByName(element.name);
      for (var n = 0, l = radio.length; n < l; n++){
        if (radio[n].checked){
          if(props[element.name] !== radio[n].value){
            let nextState = {};
            let nextAttributes = {};
  
            nextState['type'] = 'product_change';
            nextState['id'] = props.sku;
            nextAttributes['sku'] = props.sku;
            nextAttributes['field_name'] = element.name;
            nextAttributes['field_detail'] = '';
            nextAttributes['old_value'] = props[element.name];
            nextAttributes['new_value'] = radio[n].value;
            nextAttributes['title'] = element.getAttribute('placeholder');
            nextAttributes['updated_by'] = localStorage.getItem('email');
  
            nextState['attributes'] = nextAttributes
            array.push(nextState);
            break;
          } 
        }
      } 
    });

    var valid_checked = document.getElementById('state_product');
    if((props.valid==='S')!==valid_checked.checked){
      let nextState = {};
      let nextAttributes = {};

      nextState['type'] = 'product_change';
      nextState['id'] = props.sku;
      nextAttributes['sku'] = props.sku;
      nextAttributes['field_name'] = valid_checked.getAttribute('name');
      nextAttributes['field_detail'] = '';
      nextAttributes['old_value'] = props.valid;
      nextAttributes['new_value'] = valid_checked.checked? 'S' : 'N';
      nextAttributes['title'] = 'Vigente';
      nextAttributes['updated_by'] = localStorage.getItem('email');

      nextState['attributes'] = nextAttributes;
      array.push(nextState);
    }
    

    if(array.length!==0){
      setModalCheck(!modalCheck)
      setModalCheckData(array)
    }else{
      toast.warn('No se ha realizado ningun cambio');
    }
    // send_data(array, sku);
  }

  const changeStateModalChech = () => {
    setModalCheck(!modalCheck)
  }

  const loadDropdownData = () => {
    getCountries().then( response => { setCountries(response) }).catch( error => toast.error('ERROR'))
    getFamiliesData('').then( response => { setFamilies(response.data.attributes)}).catch( error => toast.error('ERROR'))
    getSubFamiliesData().then( response => { setSubfamilies(response.data.attributes)}).catch( error => toast.error('ERROR'))
    getCategories().then( response => { setCategories(response.data.attributes)}).catch( error => toast.error('ERROR'))
    getBrandsData().then( response => { setBrands(response.data.attributes)}).catch( error => toast.error('ERROR'))
    getManagersData().then( response => { setManagers(response.data.attributes)}).catch( error => toast.error('ERROR'))
  }

  // Verify Vigency
  //
  // First, verifies if the product is already active or not.
  // If the product is active, validate the stocks and the pending documents count to verify if the product can be deactivated.
  // If the product is not active, let the user activate it.
  // @returns [boolean] true if the product can be deactivated, false otherwise.
  const verifyVigency = () => {
    let vigency_condition = (props.stocks > 0 || props.pending_documents > 0) ? true : enabled
    return props.valid === 'N' ? enabled : vigency_condition
  }

  
    let {productTypeRequired, typeRequired, validRequired, rotationRequired,
      familyRequired, productProviderCodeRequired, 
      providerRequired, subfamilyRequired, barcodeRequired,
      businessRequired, categoryRequired, sourceRequired, unitRequired} = props.requiredAttribute
    
    let currentRole = localStorage.getItem('current_role')
    return(
      <form className='container attributes Product-show-attributes was-validated mb-4'>
        <ModalCheck modalcheck={modalCheck} idTable='modaltablecheckproducts' data={modalCheckData} 
        changeStateModalChech={changeStateModalChech} sku={props.sku} id_btn_edit='btn_edit_attribute' getProductData={props.getProductData}/>
        <TitleButton disabled={enabled} id_div_edit='div_edit_attribute' id_btn_edit='btn_edit_attribute' 
        title='ATRIBUTOS' id_div_save='div_send_attribute' inputEnabler={inputEnabler} check_data={checkBarcode} title_edit='Atributos'/>
        
        <div className='row'>
          <div className='col-md-10 mb-2'>
            <RadioButton
            options={['EXISTENCIA','SERVICIOS DE VENTA','OTROS']} 
            value={props.product_type} title=''
            name='product_type' disabled={true}
            required={productTypeRequired}
            description='Indica que tipo de producto es.'
            calculatePercentOfInput = {props.calculatePercentOfInput}
            flexline_name = 'tipoproducto'
            />
          </div> 

          <div className='col-md-5'>
            <InputTextAttribute title='Código proveedor' name='provider' id='id_input_provider'   
            calculatePercentOfInput = {props.calculatePercentOfInput}
            type='text' description='Código manejado por el proveedor del producto'
            value={props.provider}
            required={providerRequired}
            disabled={true}
            flexline_name = 'analisisproducto17'/>
          </div>

          <div className='col-md-5'>
            <InputTextAttribute title='Código de compra' 
            type='text'
            name='product_provider_code' id='id_product_provider_code' disabled={true} 
            value={props.product_provider_code} 
            required={productProviderCodeRequired}
            description='Indica el código de proveedor del producto.'
            calculatePercentOfInput = {props.calculatePercentOfInput}
            flexline_name = 'analisisproducto1'/>
          </div>

          <div className='col-md-5'>
            <InputTextAttribute title='Código de barra' 
            type='text'
            name='barcode' id='bar_code' 
            disabled={enabled} value={props.barcode} 
            required={barcodeRequired}
            description='Código de barra para control interno de la bodega.'
            calculatePercentOfInput = {props.calculatePercentOfInput}
            flexline_name = 'analisisproducto7'/>
          </div>

          <div className='col-md-5'>
            <SelectAtribute 
            id='select_business' options={['COMPONENTES','ORGANIZACION','ELECTRO Y MENAJE','FABRICADOS','INSTALADOS']} 
            value={props.business} title='Negocio' name='business' disabled={enabled} 
            required={businessRequired}
            description='Define negocio al cual pertenece el código.'
            calculatePercentOfInput = {props.calculatePercentOfInput}
            flexline_name = 'analisisproducto19'
            duplicate = 'clasificador1'/>
          </div>

          <div className='col-md-5'>
            <SelectAtribute id='attributes_select_family' 
            options={families} 
            value= {props.family} title='Familia'
            name='family' disabled={enabled}
            required={familyRequired}
            description='Indica la FAMILIA de producto a la cual pertenece.'
            calculatePercentOfInput = {props.calculatePercentOfInput}
            flexline_name = 'familia'
            duplicate = 'clasificador3'/>
          </div> 

          <div className='col-md-5'>
            <SelectAtribute id='attributes_select_subfamily' 
            options={subfamilies} 
            value= {props.subfamily} title='Sub-Familia' name='subfamily' disabled={enabled} 
            required={subfamilyRequired}
            description='Indica a que Subfamilia pertenece, cada subfamilia es exclusiva de una familia.'
            calculatePercentOfInput = {props.calculatePercentOfInput}
            flexline_name = 'subfamilia'
            duplicate = 'clasificador4'/>
          </div>

          <div className='col-md-5'>
            <SelectAtribute id='attributes_select_source' 
              options={countries} 
              value= {props.source.toUpperCase()} title='Procedencia' name='source' 
              disabled={props.source.trim().length!==0||enabled} 
              required={sourceRequired}
              description='Indica el origen del producto.'
              calculatePercentOfInput = {props.calculatePercentOfInput}
              flexline_name = 'procedencia'/>
          </div> 
          
          <div className='col-md-5'>
            <SelectAtribute id='select_type' 
            options={['NUEVO','LINEA','DE BAJA','A PEDIDO']} 
            value= {props.type} title='Tipo'
            name='type' disabled={enabled}
            required={typeRequired}
            description='Indica en que condición o status se encuentra el producto.'
            calculatePercentOfInput = {props.calculatePercentOfInput}
            flexline_name = 'tipo'/>
          </div> 

          <div className='col-md-5'>
            <SelectAtribute 
            id='select_category' options={categories} 
            value={props.category} title='Categoría' name='category' disabled={enabled} 
            required={categoryRequired}
            description='Uso para ordenamiento web.'
            calculatePercentOfInput = {props.calculatePercentOfInput}
            flexline_name = 'clasificador2'/>
          </div>

          <div className='col-md-5'>
            <SelectAtribute 
            id='select_unit' 
            options={unit} 
            value={props.unit} 
            title='Unidad de medida' 
            name='unit' 
            disabled={props.unit===''? enabled : true} 
            required={unitRequired}
            description='Unidad de medida del producto'
            calculatePercentOfInput = {props.calculatePercentOfInput}
            flexline_name = 'unidad'/>
          </div>
          <div className='col-md-5'>
            <SelectAtribute 
            id='select_manager' 
            options={managers} 
            value={props.manager}
            title='Encargado' 
            name='manager' 
            disabled={enabled} 
            required={true}
            description='Encargado'
            flexline_name = 'analisisproducto20'/>
          </div>

          <div className='col-md-5'>
            <SelectAtribute 
            id='select_sell' 
            options={sell} 
            value={props.sell}
            title='Compra y Venta' 
            name='sell' 
            disabled={enabled} 
            required={true}
            description='Compra Venta'
            flexline_name = 'analisisproducto11'/>
          </div>

          <div className='col-md-10'>
            <RadioButton
            options={['A','B','C','D','N','NA']} 
            value={props.rotation} title='Rotación'
            name='rotation' disabled={currentRole == 'admin'? enabled: true}
            required={rotationRequired}
            description='Indica prioridad para la compra, factor de seguridad para la compra.'
            calculatePercentOfInput = {props.calculatePercentOfInput}
            flexline_name = 'subtipo'/>
          </div>

          <div className='col-md-5'>
            <SwitchRadioButton
            id='state_product' 
            value= {props.valid==='S'} 
            title='Vigente'
            name='valid' 
            disabled = { verifyVigency() }
            required={validRequired}
            description='Indica si está disponible o no para la venta y control de stock.'
            flexline_name = 'vigente' 
            />
          </div>
        </div>
      </form>
    );
  }
          

const mapStateToProps = (state) => ({
  requiredAttribute: state.elementRequiredReducer.attribute
})

const mapDispatchToProps = (dispach) => ({
})

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(Attributes)

export default component;

