import React, { useState, useEffect } from "react";
import TitleOnlySave from '../../components/TitleOnlySave';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { updateProduct } from '../../../api/APIPost'
import es from "../../../es.json"
// import './ModalCheck.css';

function ModalCheck(props) {

  const [open,setOpen] = useState(false);

  const send_data = (data, sku,id_btn_edit) => {
    updateProduct(data, sku).then( response => {
      document.getElementById(id_btn_edit).click();
      if(response.data.attributes.aasm_state == 'waiting_approval'){
        toast.info('Los cambios fueron solicitados y estan esperando aprobación de un supervisor');
      }else{
        toast.success('Los cambios fueron aplicados.');
      }
      setTimeout( () => {
        props.getProductData(sku);
      }, 2000);
    })
    .catch(err => { toast.error('No se ha podido mandar los datos, vuelva a intentarlo') });
  }

  const check_data = () => {
    var data = props.data;
    props.changeStateModalChech();
    send_data(data, props.sku, props.id_btn_edit)
  }

  let tableCheck = props.data.map((product, i) => {
    let _ = require('lodash')
    if(product.attributes.title!==null){
      let title = product.attributes.title !== undefined ?  product.attributes.title : null
      let old_value = product.attributes.old_value !== undefined ? product.attributes.old_value : []
      let new_value = (product.attributes.new_value !== undefined) ? product.attributes.new_value : []
      if(product.attributes.field_name == 'principal_recipe' || product.attributes.field_name == 'alternative_recipe' ){
        if(_.isEqual(old_value, new_value)){ return }
        let placeholder_old_value = ''
        let placeholder_new_value = ''
        old_value.map((element) => placeholder_old_value += `${element['sku']} | ${element['description']} | ${element['line']} | ${element['quantity']} <br>`)      
        new_value.map((element) => placeholder_new_value += `${element['sku']} | ${element['description']} | ${element['line']} | ${element['quantity']} <br>`)
        old_value = placeholder_old_value
        new_value = placeholder_new_value
      }else if( product.attributes.field_name == 'product_list'){
        if(_.isEqual(old_value, new_value)){ return }
        let placeholder_old_value = ''
        let placeholder_new_value = ''
        old_value.map((element) => placeholder_old_value += `${element['sku']} | ${element['description']} <br>`)      
        new_value.map((element) => placeholder_new_value += `${element['sku']} | ${element['description']} <br>`)
        old_value = placeholder_old_value
        new_value = placeholder_new_value
      }else if(product.attributes.field_name == 'configurable_attributes'){
        if(_.isEqual(old_value, new_value)){ return }
        let placeholder_old_value = ''
        let placeholder_new_value = ''
        old_value.map((key) => placeholder_old_value += `${es['ProductItem'][key]}<br>`)      
        new_value.map((key) => placeholder_new_value += `${es['ProductItem'][key]}<br>`)
        old_value = placeholder_old_value
        new_value = placeholder_new_value
      }
  
      return(
        <tr key={i}>
          <th scope='row'>{title}</th>
          <td dangerouslySetInnerHTML={{__html: old_value}} />
          <td dangerouslySetInnerHTML={{__html: new_value}} />
        </tr>
      )
    }
    else{
      return null
    }
  });

  return(
    <div>
      <Popup
        open={props.modalcheck}
        closeOnDocumentClick
      >
        <div>
          <TitleOnlySave title={'Comprobar datos cambiados de '+props.sku} check_data={check_data}/>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Campo</th>
                <th scope='col'>Valor anterior</th>
                <th scope='col'>Valor nuevo</th>
              </tr>
            </thead>
            <tbody>
              {tableCheck}
            </tbody>
          </table>
          <button className='btn btn-danger' onClick={props.changeStateModalChech}>Salir</button>
        </div>
    </Popup>
    </div>
  );
}
export default ModalCheck;
