import React from 'react';
import Title from '../../components/Title';
import ItemImage from './ItemImage';
import { connect } from 'react-redux';

class MultimediaMainPhoto extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: false,
      new_src: []
    };

    this.inputEnabler = this.inputEnabler.bind(this);
  }

  inputEnabler(){
    this.setState({enabled: !this.state.enabled});
  }

  render() {
    let { fotAmbRequired } = this.props.requiredPhotos
    
    return(
      <div className='container multimedia_form product-show-multimedia mb-4'>
        <div className='row'>
          <div className='col-10'>
            <Title title='FOTOS MULTIMEDIA' description='Agrega o edita las fotografías'/>
          </div>
        </div>
        <ItemImage 
          getProductData={this.props.getProductData}
          sku={this.props.sku}
          photos={this.props.photos}
          title='Fotografía  ambientada'
          descript={`Esta fotografía se usá de Thumbnail con tamaño de 1000 x 1000 píxeles`}
          id_img='id_img_amb'
          id='id_fot_amb'
          usage='FotoAmbientada'
          name='name_fot_amb'
          disabled_select_img={true}
          disabled_btn_edit={false}
          max={1}
          min={1}
          required={ fotAmbRequired }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  requiredPhotos: state.elementRequiredReducer.photos
})

const mapDispatchToProps = (dispach) => ({
})

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(MultimediaMainPhoto)

export default component;
