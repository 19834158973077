import React, { useState, useEffect } from 'react';
import Required from './Required.js'
import store from '../../redux/store'

const NetPriceInput = ({
  name,
  idListaPrecio,
  value,
  enabled,
  standarCost,
  cost,
  calculatePercentOfInput,
  required,
  oldValue,
  resetValueInput,
  setResetValue,
  resetValue,
  save,
  setSave
}) => {

  const idInputIva = `price_input_${name}`
  const idPIva = `price_p_${name}`
  const idMargin = `price_margin_${name}`

  const [price, setPrice] = useState(0)
  const [ivaPrice, setIvaPrice] = useState(null)
  const [margin, setMargin] = useState(0)

  /* At the time of pressing save, and when getting the new values
  (prices), recently changed data are displayed in the view
  with the margin and the ivaprice updated with the new value. */
  useEffect(() => {
    if (save) {
      setSave(false)
      calculateInitialMargin()
    }
  }, [value])


  useEffect(() => {
    setPrice(value)
  }, [value])
  // At the time of loading the view
  useEffect(() => {
    calculateInitialMargin()
  }, []);

  // At the time of pressing undo
  useEffect(() => {
    if(resetValueInput) {
      calculateInitialMargin()
      setResetValue(false)
    }
  }, [resetValueInput])

  const calculateInitialMargin = () => {
    let ivaPrice = Math.round(value * 1.19);
    let newCost = cost === 0 ? standarCost : cost
    let margin = value == 0 ? '' : (value - newCost)/value
    margin = !margin ? '' : margin
    ivaPrice = !ivaPrice ? '' : ivaPrice

    setIvaPrice(ivaPrice)
    setPrice(value)
    setMargin(margin)
  }

  const validatePrices = (e) => {
    changeOfPrice(e)
    validateWaterfallPrice()
  }

  const changeOfPrice = (e) => {
    let price = parseInt(e.target.value)
    let ivaPrice = Math.round(price * 1.19)
    let newCost = cost == 0 ? standarCost : cost
    let margin = price == 0 ? '' : (price - newCost)/price
    margin = !margin ? '' : margin
    ivaPrice = !ivaPrice ? '' : ivaPrice

    setIvaPrice(ivaPrice)
    setPrice(price)
    setMargin(margin)
    if (required) {
      calculatePercentOfInput()
    }
  }

  const validateWaterfallPrice = () => {
    let allPrincipalPrice = document.querySelectorAll('#price_input_PUBLICO, #price_input_TIENDA, #price_input_WEB, #price_input_DISENADORES, #price_input_FABRICANTE');
    let allPrice = document.querySelectorAll('.pricelist_input');
    let publicPrice = parseInt(document.getElementById(`price_input_${name}`).value);
    switch (name) {
      case 'PUBLICO':
        allPrice.forEach( element => {
          element.classList.remove(publicPrice >= parseInt(element.value) ? 'is-invalid' : 'is-valid');
          element.classList.add(publicPrice >= parseInt(element.value) ? 'is-valid' : 'is-invalid');
        })
        break;

      case 'TIENDA':
        allPrincipalPrice.forEach( element => {
          if(element.id !== 'price_input_PUBLICO') {
            element.classList.remove(publicPrice >= parseInt(element.value) ? 'is-invalid' : 'is-valid');
            element.classList.add(publicPrice >= parseInt(element.value) ? 'is-valid' : 'is-invalid');
          } else {
            element.classList.remove(publicPrice <= parseInt(element.value) ? 'is-invalid' : 'is-valid');
            element.classList.add(publicPrice <= parseInt(element.value) ? 'is-valid' : 'is-invalid');
          }
        })
        break;

      case 'WEB':
        allPrincipalPrice.forEach( element => {
          if(element.id !== 'price_input_PUBLICO' && element.id !== 'price_input_TIENDA') {
            element.classList.remove(publicPrice >= parseInt(element.value) ? 'is-invalid' : 'is-valid')
            element.classList.add(publicPrice >= parseInt(element.value) ? 'is-valid' : 'is-invalid')
          } else {
            element.classList.remove(publicPrice <= parseInt(element.value) ? 'is-invalid' : 'is-valid');
            element.classList.add(publicPrice <= parseInt(element.value) ? 'is-valid' : 'is-invalid');
          }
        })
        break;

      case 'DISENADORES':
        allPrincipalPrice.forEach( element => {
          if(element.id !== 'price_input_PUBLICO' && element.id !== 'price_input_TIENDA' && element.id !== 'price_input_WEB') {
            element.classList.remove(publicPrice >= parseInt(element.value) ? 'is-invalid' : 'is-valid')
            element.classList.add(publicPrice >= parseInt(element.value) ? 'is-valid' : 'is-invalid')
          } else {
            element.classList.remove(publicPrice <= parseInt(element.value) ? 'is-invalid' : 'is-valid');
            element.classList.add(publicPrice <= parseInt(element.value) ? 'is-valid' : 'is-invalid');
          }
        })
        break;
        
      case 'FABRICANTE':
        allPrincipalPrice.forEach( element => {
          if(element.id !== 'price_input_PUBLICO' && element.id !== 'price_input_TIENDA' && element.id !== 'price_input_WEB' && element.id !== 'price_input_DISENADORES') {
            element.classList.remove(publicPrice >= parseInt(element.value) ? 'is-invalid' : 'is-valid')
            element.classList.add(publicPrice >= parseInt(element.value) ? 'is-valid' : 'is-invalid')
          } else {
            element.classList.remove(publicPrice <= parseInt(element.value) ? 'is-invalid' : 'is-valid');
            element.classList.add(publicPrice <= parseInt(element.value) ? 'is-valid' : 'is-invalid');
          }
        })
        break;
      default:
        break;
    }
  }

  return(
    <div className='Product-show-NetPriceInput'>
      <div className='row'>
        <div className='col-4'>
          <p className='mb-0'>
            {name}
            <Required required={required} />
          </p>
          <small className='text-secondary'>
            {name}
          </small>
        </div>
        <div className='col-8'>
          <div className='input-group'>
            <div className='input-group-prepend bg-white'>
              <span className='input-group-text bg-white'>$</span>
            </div>
            <input
              id={idInputIva}
              name={name}
              className='form-control text-right text-primary font-weight-bold pricelist_input'
              type='number'
              max='10000000'
              min='0'
              disabled={enabled}
              idlistaprecio={idListaPrecio}
              value={price}
              onChange={e => validatePrices(e)}
              placeholder={name}
              required={required}
              oldvalue={value}
            />
          </div>
        </div>
        <div className='col-4'>
          <hr className='mt-0 mb-0'/>
          <p className='text-right'>+ IVA</p>
        </div>
        <div className='col-8'>
          <div className='col-md-12 text-primary text-right '>
            <p id={idPIva}>{ivaPrice}</p>
          </div>
        </div>
        <div className='col-4'>
          <hr className='mt-0 mb-0'/>
          <p className='text-right'>MARGEN</p>
        </div>
        <div className='col-8'>
          <div className='col-md-12 text-primary text-right '>
            <p id={idMargin}>{Math.trunc(margin*1000)/10}%</p>
          </div>
        </div> 
      </div>
    </div>
  );
}

export default NetPriceInput;
