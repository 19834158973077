import global from '../data.json'

const getHeaders = () => {
  return {... global.api.header, 'X-USER-TOKEN': localStorage.getItem('token')}
}

export const getPendingChanges = (page) =>{
  return fetch(`${global.api.url}/product_changes/pending?page=${page}`, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => {
    return response;
  })
  .catch(error => console.error('Error json:', error));

}

export const getHistoryChanges = (page) => {
  return fetch(`${global.api.url}/product_changes/mixed?page=${page}`, {
      method: 'GET',
      headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => {
    return response;
  })
  .catch(error => console.error('Error json:', error));
}

export const searchHistoryChanges = (search) => {
  return fetch(`${global.api.url}/product_changes/mixed?query=${search}`, {
      method: 'GET',
      headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => {
    return response;
  })
  .catch(error => console.error('Error json:', error));
}

// Pending Changes Quantity
// Gets from API the quantity of changes waiting for approval.
// @return [Json Response] Response from API, error otherwise.
export const pendingChangesQuantity = async () => {
  try {
    const fetch_response = await fetch(`${global.api.url}/product_changes/pending_count`, {
      method: 'GET',
      headers: getHeaders()
    });
    const json_response = await fetch_response.json();
    return json_response;
  } catch (error) {
    return console.error('Error JSON: ', error);
  }
}

export const getProductsDataAll = (business, family, subfamily, category, product_type, state, query) => {
  query = query ? query : ''
  let searchQuery = `business=${business}&family=${family}&subfamily=${subfamily}&category=${category}&product_kind=${product_type}&state=${state}&query=${query}`
  var url =  encodeURI(`${global.api.url}/products?${searchQuery}`)
  return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders()
  })
  .then(response => { return response.json() })
  .then(response => { return response })
  .catch(error => console.error('Error json:', error));
}

export const getFamiliesData = (current_category) => {
  var url = `${global.api.url}/products/families`;
  const data = { category: current_category}

  return fetch(url, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
  })
  .then(response => response.json())
  .then(response => { return response })
  .catch(error => console.error('Error json:', error));
}

export const getSubFamiliesData = (current_family) => {
  let url = `${global.api.url}/products/subfamilies`;
  const data = { family: current_family }
  return fetch(url, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
  })
  .then( response => response.json() )
  .then( response => { return response} )
  .catch( error => console.error('Error json:', error) );
}

export const getBrandsData = () => {
  let url = `${global.api.url}/products/brands`;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => { return response })
  .catch(error => console.error('Error json:', error));
}

export const getManagersData = () => {
  let url = `${global.api.url}/products/managers`;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => { return response })
  .catch(error => console.error('Error json:', error));
}

export const getCategories = () => {
  var url = `${global.api.url}/products/categories`;
  return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => { return response })
  .catch(error => console.error('Error json:', error));
}

export const relatedCategories = (category, family, subfamily) => {
  var url = encodeURI(`${global.api.url}/products/related_categories?category=${category}&family=${family}&subfamily=${subfamily}`);
  return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => { return response })
  .catch(error => console.error('Error json:', error));
}


export const getShowProductData = (sku) => {
  return fetch(`${global.api.url}/products/${sku}`, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders()
  })
}

export const getProductPrice = (sku) => {
  return fetch(`${global.api.url}/products/${sku}/prices`, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => { return response.data })
  .catch(error => console.error('Error json:', error));
}

export const getCountries = () => {
  var url = `${global.api.url}/products/countries`;
  return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => { return response.data.attributes})
  .catch(error => console.error('Error json:', error));
}

export const skuAvailable = (sku) => {
  return fetch(`${global.api.url}/products/sku_validation?sku=${sku}`, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => { return response.data.attributes.available })
  .catch(error => console.error('Error json:', error));
}

// Barcode Available
//
// Fetch [GET] in API to validate if barcode is available
// @param barcode [String] Product barcode to validate
// @return [Json Response] Response from API, error otherwise.
export const barcodeAvailable = (barcode) => {
  return fetch(`${global.api.url}/products/barcode_validation?barcode=${barcode}`, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => { if(response.data){
                        return response.data.attributes.status;
                      }
                      else{
                        return response.errors[0].description[1];
                      }     
                    })
  .catch(error => console.error('Error json:', error));
}

// Technical Options
//
// Fetch [GET] in API to retrieve options from a technical attribute
// @param Technical Attribute [String] Technical Attribute
// @return [Json Response] Response from API, error otherwise.
export const technicalOptions = (technicalAttribute) => {
  return fetch(`${global.api.url}/products/technical_options?option=${technicalAttribute}`, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => { return response })
  .catch(error => console.error('Error JSON: ', error))
}

export const getBusiness = () => {
  var url = `${global.api.url}/products/business`;
  return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => { return response.data.attributes })
  .catch(error => console.error('Error json:', error));
}

export const deleteUploadFile = (sku, id) => {
  return fetch(`${global.api.url}/products/${sku}/documents/${id}/disable`, {
    method: 'GET',
    headers: getHeaders()
  })
  .then((response) => { return response })
  .catch( err => { console.log('Fetch Error :-S', err) });
}

export const exportPriceListExcel = () => {
  let headers = global.api.header
  headers['x-user-token'] = localStorage.getItem('token')
  return fetch(`${global.api.url}/products/export_price_lists`, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
  .then( response => { return response })
  .catch(error => console.error('Error json:', error));
}

export const massivePriceLists = () => {
  const url = `${global.api.url}/massive_price_changes`
  return fetch(url, {
    method: 'GET',
    headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => { return response })
  .catch( err => { console.log('Fetch Error :-S', err) });
}

export const massivePriceListDetail = (id) => {
  const url = `${global.api.url}/massive_price_changes/${id}`
  return fetch(url, {
    method: 'GET',
    headers: getHeaders()
  })
  .then(response => response.json())
  .then(response => { return response })
  .catch( err => { console.log('Fetch Error :-S', err) });
}


