import React from 'react';
import ProductList from '../product_list/ProductList'
import { Link } from 'react-router-dom';
import Pagination from "@material-ui/lab/Pagination";
import { getHistoryChanges, searchHistoryChanges } from '../../api/APIGet'
import './HistoryChange.css'

// import { data } from './data.json'
// import './Index.css';

class Index extends React.Component {

  constructor() {
    super();
    this.state = {
      selectedId: null,
      totalChanges: 0,
      totalPages: 1,
      current_page: 1,
      error: false,
      search: false,
      searchParam: ''
    }
    
    this.getProductsData = this.getProductsData.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.searchChanges = this.searchChanges.bind(this)
    this.searchProductChanges = this.searchProductChanges.bind(this)
  }

  componentDidMount(){
    this.getProductsData(this.state.current_page);
  }

  handleChange = (event, value) => {
    console.log(value)
    this.setState({current_page: value})
    if (this.state.search){
      this.searchProductChanges(this.state.searchParam, 1);
    }else{
      this.getProductsData(value);
    }
    
  }

  searchChanges = (e) => {
    if (e.key === 'Enter') {
      var search_category = document.getElementById('search_category').value;
      this.setState({searchParam: search_category, search: true, current_page: 1})
      this.searchProductChanges(search_category, 1);
    }
  }

  searchProductChanges(search, page) {
    this.setState({data: []})
    searchHistoryChanges(search).then( response => {
      console.log('response',response.meta)
      if(response!==undefined){
        if(response.data!==undefined){
          this.setState({ data: response.data});
          if(response.meta!==undefined){
            this.setState({
              current_page: page,
              totalPages: response.meta.max_pages,
            })
          }
        }else{
          this.setState({ error: true })
        }
      }else{
        this.setState({ error: true })
      }
    });
  }

  getProductsData(page) {
    this.setState({data: []})
    getHistoryChanges(page).then( response => {
      console.log('response',response.meta)
      if(response!==undefined){
        if(response.data!==undefined){
          this.setState({ data: response.data});
          if(response.meta!==undefined){
            this.setState({
              current_page: page,
              totalPages: response.meta.max_pages,
            })
          }
        }else{
          this.setState({ error: true })
        }
      }else{
        this.setState({ error: true })
      }
    });
  }

  render() {
    let {current_page, totalPages, data, error} = this.state
    if(data===undefined || data===null){
      if(error){
        return(
          <div className='row'>
            <div className='col-md-12 mt-5 text-center'>
              <h1 className='text-primary'>HAY UN ERROR</h1>
            </div>
          </div>
        )
      }else{
        return null;
      }
    }

    return(
      <div className='row'>
        <hr className='col-md-12'/>
        <div className='col-md-4'>
          <h3 className='ml-2'>CAMBIOS REALIZADOS</h3>
          <input id='search_category' className='search_category form-control' placeholder='Buscar Cambio por sku' onKeyDown={this.searchChanges} />
        </div>
        <div className='col-md-4'>
          <Pagination count={this.state.totalPages} page={this.state.current_page} onChange={this.handleChange} color="primary" className="justify-content-center"/>
        </div>
        <div className='col-md-4 text-right'>
          <Link className='btn btn-primary' to='/productchanges/'>Cambios pendientes</Link>
        </div>
        
        <div className='col-md-12 mt-3'>
          <ProductList data={data} getProductsData={this.getProductsData} pending_change={false}/>
        </div>
      </div>
    );
  }
}

export default Index;
