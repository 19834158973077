import React from 'react'
import { publishProduct } from '../../api/APIPost'
import { toast } from 'react-toastify';
import store from '../../redux/store'

class CheckBoxPublish extends React.Component {
  constructor() {
    super();
    this.state = {}
    this.setPublishProduct = this.setPublishProduct.bind(this)
  }

  setConditionPublish() {
    let pricesCheck = store.getState().priceCheckReducer.pricesCheck
    let lengthPricesCheck = Object.keys(pricesCheck).length
    for (let i = 0; i < lengthPricesCheck; i++) {
      if(!pricesCheck[i].value) {
        return false
      }
    }
    return true
  }

  setPublishProduct() {
    if(this.props.currentRole == 'basic') { return }
    let { type, id } = this.props
    let to = id !== 'publish' ? `_${type}` : ``
    let element = document.getElementById(id)
    let kind = element.checked ? `publish${to}` : `unpublish${to}`
    let sku = document.getElementById('input_sku').value
    
    publishProduct(sku, kind).then(response => {
      if(response.data.attributes.aasm_state == 'waiting_approval'){
        return toast.info('El cambio fue solicitado y se está procesando')
      }
      element.checked ?  toast.success('El producto fue publicado') : toast.warn('El producto fue retirado');
    }).catch( err => {
      toast.error('No se ha podido mandar los datos, vuelva a intentarlo');
    });
    
  }

  render() {
    let { type, id } = this.props
    return(
      <li className='mx-2'>
        <input
          type="checkbox"
          id={id}
          className='check-publish'
          value={type}
          disabled={this.props.currentRole == 'basic'}
          onClick={() => { this.setPublishProduct() }}
        />
        <label
          id={`${id}-text`}
          htmlFor={id}
        >
          {type.toUpperCase()}
        </label>
      </li>
    )
  }
}

export default CheckBoxPublish;
