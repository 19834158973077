import React from 'react';
import TitleButton from '../../components/TitleButtonEdit';
import SelectAtribute from '../../components/SelectAttribute';
import InputTextAttribute from '../../components/InputTextAttribute';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalCheck from '../modal_check/ModalCheck';
import { connect } from 'react-redux'

class Price extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: true,
      modalcheck: false,
      modalcheckData: [],
      sku: ''
    };

    this.inputEnabler = this.inputEnabler.bind(this);
    this.check_data = this.check_data.bind(this);
    this.changeStateModalChech = this.changeStateModalChech.bind(this);
  }

  inputEnabler(){
    this.setState({enabled: !this.state.enabled});
  }

  changeStateModalChech(){
    this.setState({modalcheck: !this.state.modalcheck});
  }

  componentWillReceiveProps(){
    this.setState({enabled: true});
  }
  

  check_data(){
    let array = [];
    let sku = document.getElementById('input_sku').value;

    document.querySelectorAll('.price input[type=number],.price input[type=text], .price select').forEach((select) => {
      let name = select.getAttribute('name');
      let value = select.value.toUpperCase();
      if(value !== this.props[name].toString()){
        let nextState = {};
        let nextName = {};

        nextState['type'] = 'product_change';
        nextState['id'] = sku;
        nextName['sku'] = sku;
        nextName['field_name'] = name;
        nextName['field_detail'] = '';
        nextName['old_value'] = this.props[name];
        nextName['new_value'] = value;
        nextName['title'] = select.getAttribute('placeholder');
        nextName['updated_by'] = localStorage.getItem('email');

        nextState['attributes'] = nextName
        array.push(nextState);
      }
    });

    if(array.length!==0){
      this.setState({modalcheck: !this.state.modalcheck});
      this.setState({modalcheckData: array});
      this.setState({sku: sku});
    }else{
      toast.warn('No se ha realizado ningun cambio');
    }
  }

  

  render() {
    const checkRole = (role) => {
      return role == localStorage.getItem('current_role')
    }
    var cost = this.props.cost;
    let {purchase_currency, purchase_price, selling_price, standar_cost} = this.props.requiredPrices
    let roleClass =  (checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? '' : 'd-none'
    return(
      <form className={`container price Product-show-Price was-validated ${roleClass}`}>
        <ModalCheck modalcheck={this.state.modalcheck} idTable='modaltablecheckproducts' data={this.state.modalcheckData} 
        changeStateModalChech={this.changeStateModalChech} sku={this.state.sku} id_btn_edit='btn_edit_price' getProductData={this.props.getProductData}/>
        <TitleButton disabled={this.state.enabled} id_div_edit='div_edit_price' id_btn_edit='btn_edit_price' 
        title='COSTO' id_div_save='div_send_price' inputEnabler={this.inputEnabler} check_data={this.check_data} title_edit='Precio'/>
        <div className='row'>
          <div className='col-md-5'>
            <SelectAtribute id='attributes_select_purchase_currency' options={['DL','EUR','PS', 'CLP']} 
            value= {this.props.purchase_currency} 
            title='Moneda' 
            name='purchase_currency'
            disabled={this.state.enabled}
            required={purchase_currency}
            description='Moneda de compra, ya sea Dólar DL, Euro EUR y Peso Chileno PS'
            flexline_name='analisisproducto22'/>
          </div> 

          <div className='col-5'>
            <InputTextAttribute title='Precio Compra' name='purchase_price' id='id_input_purchase_price' disabled={this.state.enabled} 
            measurement={true}
            calculatePercentOfInput = {this.props.calculatePercentOfInput}
            value={this.props.purchase_price.replace(',','.')} type='number' price={true}
            required={purchase_price}
            description='Indica el valor de compra en moneda extranjera.'
            flexline_name='analisisproducto23'/>
          </div>

          <div className='col-5'>
            <InputTextAttribute title='Precio Venta' name='selling_price' id='id_input_selling_price' disabled={this.state.enabled} 
            calculatePercentOfInput = {this.props.calculatePercentOfInput}
            value={this.props.selling_price} type='number' max='10000000' min={this.props.cost===''? this.props.standar_cost : this.props.cost} price={true}
            required={selling_price}
            description='Valor de venta de referencia.'
            flexline_name='precioventa'/>
          </div>

          <div className='col-5'>
            <InputTextAttribute title='Costo Estandar' name='standar_cost' id='id_input_standar_cost' 
            disabled={cost.length!==0&&cost!==0&&cost!==null&&cost!==undefined? true : this.state.enabled} 
            calculatePercentOfInput = {this.props.calculatePercentOfInput}
            value={this.props.standar_cost} type='number' max='10000000' min='0' price={true}
            required={standar_cost}
            description='Es un valor de costo referencial que es aplicado en forma manual, esto se hace para tener un valor de un producto que aún no ha sido costeado.'
            flexline_name='costoestandar'/>
          </div>
 
          <div className='col-5'>
            <InputTextAttribute title='Costo Real' name='cost' id='id_input_cost' disabled={true} 
            calculatePercentOfInput = {this.props.calculatePercentOfInput}
            value={this.props.cost} type='number' max='10000000' min='0' price={true} 
            required={false}
            description='El valor de resultado del proceso de Costeo de Mercadería, indica el costo del producto puesto en nuestras bodegas.'
            flexline_name='costo'/>
          </div>
          
        </div>
      </form>
    );
  }
}


const mapStateToProps = (state) => ({
  requiredPrices: state.elementRequiredReducer.prices
})

const mapDispatchToProps = (dispach) => ({})

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(Price)

export default component;
