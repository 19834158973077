import React from 'react';
import Required from './Required.js'
import { connect } from 'react-redux'

class SelectAtribute extends React.Component {

  constructor(props) {
    super(props);
    this.state = {enabled: true};

    this.changeRadioButton = this.changeRadioButton.bind(this)
  }
  
  componentDidUpdate(){
    let { value, name, flexline_name } = this.props
    if(value!==undefined && value.length!==0){
      if(flexline_name==='tipoproducto'){
        value = value==='SERVICIOS'? 'SERVICIOS DE VENTA' : value
        value = value==='SERVICIOS'||value==='SERVICIOS DE VENTA'||value==='EXISTENCIA'?  value : 'OTROS'
      }
      document.getElementById(`radio_${value}`).checked = true;
    }else{
      name = document.getElementsByName(name);
      if(value===''){
        for (let i = 0; i < name.length; i++) {
          name[i].checked = false;
        }
      }
    }
  }

  componentDidMount(){
    let { value, name, flexline_name } = this.props
    if(value!==undefined && value.length!==0){
      if(flexline_name==='tipoproducto'){
        value = value==='SERVICIOS'? 'SERVICIOS DE VENTA' : value
        value = value==='SERVICIOS'||value==='SERVICIOS DE VENTA'||value==='EXISTENCIA'?  value : 'OTROS'
      }
      document.getElementById(`radio_${value}`).checked = true;
    }else{
      name = document.getElementsByName(name);
      if(value===''){
        for (let i = 0; i < name.length; i++) {
          name[i].checked = false;
        }
      }
    }
  }

  changeRadioButton(value){
    let { calculatePercentOfInput, changeElementRequiered} = this.props
    let elementSelected = document.getElementById(`radio_${value}`).value
    if(changeElementRequiered){
      this.props.changeRequiredState(elementSelected)
    }
    setTimeout(() =>{
      calculatePercentOfInput();
    }, 1);
  }

  render() {
    let radio_buttons = this.props.options.map((value,i)=>{
      return(
        <div className={`col`} key={i}>
          <div className='custom-control custom-radio custom-control-inline'>
            <input type='radio' id={`radio_${value}`} name={this.props.name} className='custom-control-input' disabled={this.props.disabled}
            value={value} required={i===0? this.props.required : ''} onChange={ () => this.changeRadioButton(value)} 
            placeholder={this.props.title===''? this.props.flexline_name : this.props.title}/>
            <label className='custom-control-label' htmlFor={`radio_${value}`}>
              {value}
            </label>
          </div>
        </div>
      );
    });
    return(
      <div className='row Product-show-attributes mb-3'>
        <div className='col-md-2'>
          <p className='mb-0' data-toggle='tooltip' data-placement='right' title={this.props.description}>
            {this.props.title}
            <Required required={this.props.required} />
          </p>
          <small className='text-secondary'>
            {this.props.flexline_name}
          </small>
        </div>
        {radio_buttons}
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  requiredAttribute: state.elementRequiredReducer.attribute
})

const mapDispatchToProps = (dispach) => ({
  changeRequiredState(typeSelected){
    dispach({
      type: "CHANGE_REQUIRED",
      typeSelected
    })
  },
})

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(SelectAtribute)

export default component;
