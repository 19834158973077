import React, { useState, useEffect } from 'react';
import { getShowProductData } from '../../api/APIGet'
import { ToastContainer, toast } from "react-toastify";
import es from '../../es.json'

// Render ProductListTable
// Add products to the list with sku
// enter a valid sku in the input
// then click add to add the product to the table
// if valid, connect to api and get the product description
// returns render ProductListTable Component
function ProductListTable(props){

  const [sku, setSku] = useState('')
  const [description, setDescription] = useState('')
  const [tableRows, setTableRows] = useState(props.tableRows)
  const [formClass, setFromClass] = useState('')

  // Show or hide element depending if form is show/edit or create
  useEffect(()=>{
    setFromClass(props.formKind == 'show' ? 'd-none' : 'block')
    if(props.formKind != 'new') { setTableRows(props.tableRows) }
  },[])

  // Listen to props table rows
  // If props change, change local state table rows
  useEffect(()=>{
    setTableRows(props.tableRows)
  },[props.tableRows])

  // Listen to description state change
  // if change add the producto to the tableRow
  useEffect(()=>{
    handleAditionToRecipe()
  },[description])

  // Listen to props enabled
  // if changed, set the table rows state
  // with the props table rows value
  useEffect(()=>{
    setTableRows(props.tableRows)
  },[props.enabled])

  // Show or hide element depending if form is show/edit or create
  useEffect(()=>{
    setFromClass(props.formKind == 'show' ? 'd-none' : 'block')
  },[props.formKind])

  // HandleSubmit
  // Get description of submitted product sku
  const handleSubmit = () => {
    if(sku != ''){
      fetchDescription(sku)
    }
  }

  // Handle addition to recipe
  // 
  // get the product by sku
  // Add product description to table
  // then clean input
  // returns nil
  const handleAditionToRecipe = () => {
    if(description == '') { return }
    let presenceFlag = false
    tableRows.map((row) => {
      if(row['sku'] == sku){ 
        presenceFlag = true
      }
    })
    if(!presenceFlag){ 
      setTableRows([...tableRows, { sku: sku, description: description}])
      props.setTableRows({ sku: sku, description: description}, 'add')
      if(props.sku == ''){
        props.setSku(sku)
      }
    }
    clearFields()
  }

  // Delete Row
  // 
  // Find row by id
  // then remove row from the array
  // returns nil
  const deleteRow = (event) => {
    let sku = parseInt(event.target.id.replace('delete_', ''))
    let newRows = tableRows
    newRows.splice(sku, 1)
    setTableRows(newRows)
    props.setTableRows(newRows, 'delete')
    console.log('NewRows lenght', newRows.length)
    if(props.setSku && newRows.length === 0){
      props.setSku('')
    }
  }

  // Clear Fields
  // 
  // clear sku input field
  // to add another product
  // returns nil
  const clearFields = () => {
    setSku('')
    setDescription('')
  }

  // Fetch description
  // 
  // Find product by sky
  // then store product description in the description state
  // If the sku is invalid, show error toast
  const fetchDescription = async (sku) => {
    const response_json = await getShowProductData(sku)
                          .then(response => response.json())
    if(response_json.data != undefined) { setDescription(response_json.data.attributes.gloss) }
    else { toast.error(es['ProductListTable']['invalid_sku']) }
  }

  return(
    <>
      <h5 className='mb-2'>{ es['ProductListTable'][props.name].toUpperCase() }</h5>
      <table className='table' name={ props.name }>
        <thead>
          <tr>
            <th className='bolder-text col-md-1 table-text-new'> {es['ProductListTable']['sku']} </th>
            <th className='bolder-text col-md-3 table-text-new'> {es['ProductListTable']['description']} </th>
            <th className='small col-md-1'> </th>
          </tr>
        </thead>
        <tbody>
          {
            (tableRows).map((row, index) => {
              return(
                <tr id={ `${props.name}_${index}` } key={ `${props.name}_${index}_key` }>
                  <td className='small table-text-new'>{ row['sku'] }</td>
                  <td className='small table-text-new'>{ row['description'] }</td>
                  <td className='small'>
                  <button id={`delete_${index}`} data-row={`${props.name}_${index}`} className={`btn btn-danger btn-sm btn-block ${formClass}`} type='button'onClick={ e => deleteRow(e) }>
                    Borrar
                  </button>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <div className={`row ${formClass}`}>
        <div className={`form-group col-md-11`}>
          <input 
            type='text'
            value={ sku }
            name={ `${props.name}_sku` }
            className='form-control text-uppercase'
            id={ `${props.name}_sku` }
            placeholder={es['ProductListTable']['sku']}
            onChange={ e => setSku(e.target.value) }
            onKeyPress={(e) => {
              if(e.key === "Enter") {
                handleSubmit();
                e.target.value = ''
              }
          }}/>
        </div>
        <div className={`form-group col-md-1 text-right`}>
          <button 
            className='btn btn-primary'
            id={ `add_${props.name}` }
            type='button'
            onClick={e => handleSubmit(e)}
            >
            <i className='fa fa-plus-sign'></i>
          </button>
        </div>
      </div>
    </>
  )
}

export default ProductListTable;
