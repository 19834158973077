import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { uploadPriceLists } from "../api/APIPost"
import { massivePriceLists } from "../api/APIGet"
import ReactLoading from 'react-loading';
import es from '../es.json';
import './ProductPrices.css'

const ProductPrices = () => {

  const [files, setFiles] = useState([])
  const [loadIndex, setLoadIndex] = useState(true)

  useEffect(() => {
    retrieveMassivesUpload()
  }, [])

  const selectFile = () => {
    let element = document.getElementById('upload_products_price_lists');
    if(element!==null) element.click();
  }

  const checkFileName = (file) => {
    if(file.includes(".xlsx")){
      let file_names = files.map(api_file => api_file["attributes"]["name"])
      if(file_names.includes(file)){
        toast.warn("Nombre de archivo duplicado.")
        return true
      }
      else{
        return false
      }
    }
    else{
      toast.warn("Porfavor, cargar un archivo con extensión .xlsx")
      return true
    }
  }

  const uploadExcel = () => {
    let element = document.getElementById('upload_products_price_lists');
    if(element!==null){
      let file = element.files[0];
      if(checkFileName(file['name'])) return
      uploadPriceLists(file)
      .then(res => {
        if (res.status===204) {
          setTimeout( () => {
            toast.success('El documento se subió exitosamente!');
            retrieveMassivesUpload()
          }, 2000);
        } else { toast.warn('Error interno, No se ha podido subir su documento'); }
      })
      .catch( err => toast.error('Error interno, No se ha podido subir su documento'));
    }
  }

  const retrieveMassivesUpload = () => {
    setLoadIndex(true)
    massivePriceLists().then(res => {
      if(res.data.length >= 0) {
        setFiles(res.data)
        setLoadIndex(false)
      }
      else {
        setFiles([])
      }
    })
  }


  return (
    <div>
      <div className='product-prices-layout'>
        <div className='title'>
          <h3> { es['Common']['uploaded_files'] } </h3>
        </div>
        <div className='upload-button'>
          <button  className='btn btn-primary' onClick={() => selectFile()}>
            { es['Common']['upload_file'] }
          </button>
          <input id='upload_products_price_lists'  name='upload_products_price_lists' type='file' hidden={true} accept='.xlsx,.csv,.xls' onChange={(file) => uploadExcel(file)} />
        </div>
        <div className='upload-table'>
          { loadIndex ? 
            <div id='product_prices_loader' className='text-center d-flex mt-5 justify-content-center'>
              <div className='mt-5'>
                <ReactLoading delay={2000 /1000} color={'#544f92'} type='spokes'/>
              </div>
            </div>
          :
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    <th className='file-column' scope='col'> { es["Common"]["file_name"] } </th>
                    <th className='file-column' scope='col'> { es["Common"]["state"] } </th>
                    <th className='detail-column' scope='col'> { es["Common"]["detail"] }</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    files.length > 0 ?
                      files.map((data) => {
                        return( 
                          <tr id={ `row_${data['attributes']['name']}` }>
                            <td className='file-column' > { data['attributes']['name'] } </td>
                            <td className='file-column' > { es["Common"][data['attributes']['aasm_state']] } </td>
                            <td className='detail-column'>
                            <Link to={{ pathname:`/products_prices_detail/${data['attributes']['id']}`, productsPricesId: data['attributes']['id'] }}>
                              <button className='btn btn-primary' type='button'>
                                { es["Common"]["detail"] }
                              </button>
                            </Link>
                            </td>
                          </tr>
                        )
                      })
                    :
                      null
                  }
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ProductPrices;
