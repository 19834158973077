import React from 'react';
import TitleOnlySave from '../../components/TitleOnlySave';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { createProduct, createCustomProduct } from '../../../api/APIPost'
import Title from '../../../components/Title';
// import './ModalCheck.css';

function SaveProduct(props){
  if(props.elementNCheck){
    return(
      <TitleOnlySave title={''} check_data={props.check_data}/>
    );
  }
  else{
    return(
      <Title title='Complete los campos requeridos' />
    );
  }
}
class ModalCheck extends React.Component {

  constructor(props) {
    super(props);
    this.state = { open: false };
    this.check_data = this.check_data.bind(this);
  }

  check_data(){
    this.props.changeStateModalChech();
    if(this.props.selectedKind == 'configurable'){
      createCustomProduct(this.props.data).then( response => {
        toast.success('El producto se agregó con éxito');
        setTimeout( () => {
          window.location.assign('/product/'+this.props.sku)
        }, 2000);
      }).catch( err => toast.error('No se ha podido mandar los datos, vuelva a intentarlo') );
    }else{
      createProduct(this.props.data).then( response => {
        toast.success('El producto se agregó con éxito');
        setTimeout( () => {
          window.location.assign('/product/'+this.props.sku)
        }, 2000);
      }).catch( err => toast.error('No se ha podido mandar los datos, vuelva a intentarlo') );
    } 
  }

  
  render() {
    var elementNCheck = this.props.allElementNotChecked
    
    if(this.props.modalcheck && elementNCheck!=='Campos'){
      return null
    }

    return(
      <div>
        <Popup
          open={this.props.modalcheck}
          closeOnDocumentClick
        >
          <div className='container'>
            <SaveProduct elementNCheck={elementNCheck==='Campos'} check_data={this.check_data} sku={this.props.sku} selectedKind={this.props.selectedKind}/>
            <div className='row mt-3'>
              <div className='col-12 text-center'>
                <h4 className={elementNCheck==='Campos'? 'text-success' : 'text-danger'}>
                  {elementNCheck==='Campos'? 'Puede guardar sin problema' : `${elementNCheck} campos faltan por completar`}
                </h4>
                <h6>Compruebe los datos antes de guardar</h6>
              </div>
              <div className='col-3'>
                <button className='btn btn-danger btn-block' onClick={this.props.changeStateModalChech}>Salir</button>
              </div>
            </div>
          </div>
      </Popup>
      </div>
    );
  }
}
export default ModalCheck;
