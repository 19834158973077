import React from 'react';
import Title from '../../components/Title';
import ItemImage from './ItemImage';
import { connect } from 'react-redux';
// import './Multimedia.css';

class Multimedia extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: false,
      new_src: []
    };

    this.inputEnabler = this.inputEnabler.bind(this);
  }

  inputEnabler(){
    this.setState({enabled: !this.state.enabled});
  }

  render() {
    let { fotAmbRequired } = this.props.requiredPhotos
    
    return(
      <div className='container multimedia_form product-show-multimedia mb-4'>
        <div className='row'>
          <div className='col-10'>
            <Title title='FOTOS MULTIMEDIA' description='Agrega o edita las fotografías'/>
          </div>
        </div>
        <ItemImage 
          getProductData={this.props.getProductData}
          sku={this.props.sku}
          photos={this.props.photos}
          title='Fotografía  ambientada'
          descript={`Esta fotografía se usá de Thumbnail con tamaño de 1000 x 1000 píxeles`}
          id_img='id_img_amb'
          id='id_fot_amb'
          usage='FotoAmbientada'
          name='name_fot_amb'
          disabled_select_img={true}
          disabled_btn_edit={false}
          max={1}
          min={1}
          required={fotAmbRequired}
        />
        <ItemImage 
          getProductData={this.props.getProductData}
          sku={this.props.sku}
          photos={this.props.photos}
          title='Fotografía técnica'
          descript='Fotografía con tamaño de 1000 x 1000 píxeles'
          id_img='id_img_tec'
          id='id_fot_tec'
          usage='FotoTecnica'
          name='name_fot_tec1'
          disabled_select_img={false}
          disabled_btn_edit={false}
          max={4}
          min={1}
        />
        <ItemImage 
          getProductData={this.props.getProductData}
          sku={this.props.sku}
          photos={this.props.photos}
          title='Fotografía Descripción'
          descript='Fotografía con tamaño de 400 x 340 píxeles'
          id_img='id_img_desc'
          id='id_fot_desc'
          usage='FotoDescripcion'
          name='name_fot_descr1'
          disabled_select_img={true}
          disabled_btn_edit={false}
          max={2}
          min={0}
        />
        <ItemImage 
          getProductData={this.props.getProductData}
          sku={this.props.sku}
          photos={this.props.photos}
          title='Fotografía Atributo'
          descript='Fotografía con tamaño de 488 x 540 píxeles'
          id_img='id_img_Attribute'
          id='id_fot_attribute'
          usage='FotoAtributo'
          name='name_fot_Attribute'
          disabled_select_img={true}
          disabled_btn_edit={false}
          max={3}
          min={0}
        />
        <ItemImage 
          getProductData={this.props.getProductData}
          sku={this.props.sku}
          photos={this.props.photos}
          title='Fotografía Magic Info'
          descript='Fotografía vertical de 1080x1920 píxeles con formato jpg sobre 300 ppi '
          id_img='id_img_Magic'
          id='id_fot_mag'
          usage='MagicInfo'
          name='name_fot_Mag1'
          disabled_select_img={true}
          disabled_btn_edit={false}
          max={5}
          min={0}
        />
        <ItemImage 
          getProductData={this.props.getProductData}
          sku={this.props.sku}
          photos={this.props.photos}
          title='Fotografía de embalaje'
          descript=''
          id_img='id_img_encomienda'
          id='id_fot_encomienda'
          usage='FotoEncomienda'
          name='photo_order1'
          disabled_select_img={true}
          disabled_btn_edit={false}
          max={2}
          min={0}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  requiredPhotos: state.elementRequiredReducer.photos
})

const mapDispatchToProps = (dispach) => ({
})

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(Multimedia)

export default component;
