import React from "react";
import Required from "./Required.js";

class SelectAtribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = { enabled: true };
  }

  componentDidUpdate() {
    let { value, id } = this.props;
    if (value !== undefined) {
      let generalSelect = document.getElementById(`${id}`);
      if (value !== "" && generalSelect.value === "") {
        let option = document.createElement("option");
        option.value = value;
        option.innerHTML = value;
        generalSelect.appendChild(option);
      }
      generalSelect.value = value;
    }
  }

  render() {
    let options = [];
    if (this.props.options !== undefined) {
      options = this.props.options.map((element, key) => {
        element = Array.isArray(element) ? element[0] : element;
        return element.trim().length !== 0 ? (
          <option value={element} key={key}>
            {element}
          </option>
        ) : null;
      });
    }

    return (
      <div className="row Product-show-attributes mb-3">
        <div className="col-md-4">
          <p
            className="mb-0"
            data-toggle="tooltip"
            data-placement="right"
            title={this.props.description}
          >
            {this.props.title}
            <Required required={this.props.required} />
          </p>
        </div>

        <div className="col-md-8">
          <select
            id={this.props.id}
            name={this.props.name}
            className="form-control form-control-sm"
            disabled={this.props.disabled}
            required={this.props.required}
            // onChange={this.props.calculatePercentOfInput}
            placeholder={this.props.title}
            defaultValue={this.props.value}
            title=""
            duplicate={this.props.duplicate}
          >
            <option value="" disabled={true}></option>
            {options}
          </select>
        </div>
        <div className="col-md-12">
          <small className="text-secondary">
            {`${this.props.flexline_name} ${
              this.props.duplicate !== undefined
                ? "| " + this.props.duplicate
                : ""
            }`}
          </small>
        </div>
      </div>
    );
  }
}
export default SelectAtribute;
