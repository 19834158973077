import React, { useState, useEffect } from 'react';
import { getShowProductData } from '../../api/APIGet'
import { ToastContainer, toast } from "react-toastify";
import es from '../../es.json'

function RecipeTable(props){

  const [sku, setSku] = useState('')
  const [quantity, setQuantity] = useState('')
  const [description, setDescription] = useState('')
  const [line, setLine] = useState('')
  const [tableRows, setTableRows] = useState(props.tableRows)
  const [formClass, setFromClass] = useState('')

  useEffect(()=>{
    setFromClass(props.formKind == 'show' ? 'd-none' : 'block')
    if(props.formKind != 'new') { setTableRows(props.tableRows) }
    handleLines()
  },[])

  useEffect(()=>{
    setTableRows(props.tableRows)
  },[props.tableRows])

  useEffect(()=>{
    if(tableRows.length != 0) { setLine(tableRows[tableRows.length - 1]['line'] + 1) }
    if(isNaN(line)){
      setLine(1)
    }
  },[line])

  useEffect(()=>{
    handleAditionToRecipe()
  },[description])

  useEffect(()=>{
    setTableRows(props.tableRows)
    handleLines()
  },[props.enabled])

  useEffect(()=>{
    setFromClass(props.formKind == 'show' ? 'd-none' : 'block')
  },[props.formKind])

  useEffect(()=>{
  },[formClass])

  const handleSubmit = () => {
    if(sku != '' && quantity != ''){
      if(quantity < 0) { 
        toast.error('Error: Cantidad negativa')
        return
      }
      fetchDescription(sku)
    }
  }

  const handleLines = () => { 
    if(tableRows.length == 0){ setLine(1) }
    else{ setLine(parseInt(tableRows[tableRows.length - 1][2]) + 1) }
  }

  const handleAditionToRecipe = () => {
    if(description == '') { return }
    let presenceFlag = false
    tableRows.map((row) => {
      if(row['sku'] == sku){
        row['quantity'] = Number(row['quantity']) +quantity
        row['isUpdated'] = true
        presenceFlag = true
      }
    })
    if(!presenceFlag){ 
      setTableRows([...tableRows, { sku: sku, description: description, line: line, quantity: quantity }])
      props.setTableRows({ sku: sku, description: description, line: line, quantity: quantity , isUpdated: true}, props.name, 'add')
      setLine(line + 1)
    }
    clearFields()
  }

  const deleteRow = (event) => {
    let line = parseInt(event.target.id.replace('delete_', '')) - 1
    let newRows = tableRows.splice(line, 1)
    setTableRows(newRows)
    props.setTableRows(newRows, props.name, 'delete')
    reassignLines()
  }

  const reassignLines = () => { 
    let reassignedRows = []
    let lastLine
    tableRows.map((row, index) => {
      let newRow = row
      newRow['line'] = index + 1
      reassignedRows.push(newRow)
      lastLine = index + 1
    })
    setLine(lastLine + 1)
    setTableRows(reassignedRows)
    props.setTableRows(reassignedRows, props.name, 'replace')
  }

  const clearFields = () => {
    setSku('')
    setQuantity('')
    setDescription('')
  }

  const fetchDescription = async (sku) => {
    const response_json = await getShowProductData(sku)
                          .then(response => response.json())
    if(response_json.data != undefined) { setDescription(response_json.data.attributes.gloss) }
    else { toast.error('Error: SKU no corresponde a ningún producto') }
  }

  return(
    <>
      <p className='mb-2'>{ es['RecipeTable'][props.name] }</p>
      <table className='table' name={ props.name }>
        <thead>
          <tr>
            <th className='small col-md-4'> SKU </th>
            <th className='small col-md-4'> Descripción </th>
            <th className='small col-md-2'> Línea </th>
            <th className='small col-md-2'> Cantidad </th>
            <th className='small col-md-2'> </th>
          </tr>
        </thead>
        <tbody>
          {
            (tableRows).map((row, index) => {
              return(
                <tr id={ `${props.name}_${index}` } key={ `${props.name}_${index}_key` }>
                  <td className='small'>{ row['sku'] }</td>
                  <td className='small'>{ row['description'] }</td>
                  <td className='small'>{ row['line'] }</td>
                  <td className='small'>{ row['quantity'] }</td>
                  <td className='small'>
                  <button id={`delete_${row['line']}`} className={`btn btn-danger btn-sm btn-block ${formClass}`} type='button'onClick={ e => deleteRow(e) }>
                    Borrar
                  </button>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <div className={`row ${formClass}`}>
        <div className={`form-group col-md-6`}>
          <input 
            type='text'
            value={ sku }
            name={ `${props.name}_sku` }
            className='form-control text-uppercase'
            id={ `${props.name}_sku` }
            placeholder='SKU'
            onChange={ e => setSku(e.target.value) }/>
        </div>
        <div className={`form-group col-md-4`}>
          <input
            type='number'
            value={ quantity }
            name={ `${props.name}_quantity` }
            className='form-control text-uppercase'
            id={ `${props.name}_quantity` }
            placeholder='Cantidad'
            min='0.01'
            step='any'
            onChange={ e => setQuantity(+(e.target.value)) }/>
        </div>
        <div className={`form-group col-md-2`}>
          <button 
            className='btn btn-primary'
            id={ `add_${props.name}` }
            type='button'
            onClick={e => handleSubmit(e)}
            >
            <i className='fa fa-plus-sign'></i>
          </button>
        </div>
      </div>
    </>
  )
}

export default RecipeTable;
