import React, { useState, useEffect } from 'react';
import TitleButtonPriceListGeneral from '../../components/TitleButtonPriceListGeneral';
import NetPriceInput from '../../components/NetPriceInput';
import DiscountInput from '../../components/DiscountInput';
import { toast } from 'react-toastify';
import ModalCheck from '../modal_check/ModalCheck';
import { Link } from 'react-router-dom';
import { getProductPrice } from '../../../api/APIGet'
import { useParams } from 'react-router-dom'

const PriceList = () => {

  const { id } = useParams()
  const [enabled, setEnabled] = useState(true)
  const [modalCheckData, setModalCheckData] = useState([])
  const [modalCheck, setModalCheck] = useState(false)
  const [priceList, setPriceList] = useState(null)
  const [cost, setCost] = useState(null)
  const [standarCost, setStandarCost] = useState(null)
  const [resetValueInput, setResetValue] = useState(false)
  const [save, setSave] = useState(false)
  const [renderSuccess, setRenderSuccess] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllPriceList(id)
  }, [id])

  const inputEnabler = () => {
    setEnabled(!enabled)
  }

  const resetValue = () => {
    setResetValue(true)
    setEnabled(!enabled)
  }

  const changeStateModalCheck = () => {
    setModalCheck(!modalCheck)
  }

  const getAllPriceList = (sku) => {
    getProductPrice(sku).then(response => {
      setPriceList(response)
      setCost(Math.round(response.attributes.cost))
      setStandarCost(response.attributes.standar_cost)
      setRenderSuccess(true)
    })
    .catch(error => toast.error('Hubo un problema al obtener las listas de precio'));
  }

  const checkData = () => {
    let array = []
    let sku = id
    document.querySelectorAll('.listprice input[type=number]').forEach((select) => {
      let name = select.getAttribute('name')
      let value = select.value.trim()
      var oldValue = Math.round(select.getAttribute('oldValue'))
      var newValue = Math.round(value)

      if(newValue !== oldValue){
        let nextState = {}
        let nextName = {}
        nextState['type'] = 'price_change'
        nextState['id'] = select.getAttribute('idListaPrecio')
        nextName['sku'] = sku
        nextName['field_name'] = name
        nextName['field_detail'] = select.classList.contains('max-discount') ? 'max_discount' : '';
        nextName['old_value'] = oldValue
        nextName['new_value'] = newValue
        nextName['title'] = select.getAttribute('placeholder')
        nextName['updated_by'] = localStorage.getItem('email')
        nextState['attributes'] = nextName
        array.push(nextState)
      }
    });
    
    if (array.length!==0) {
      setModalCheckData(array)
      setModalCheck(!modalCheck)
    } else {
      toast.warn('No se ha realizado ningun cambio');
    }
  }

  const renderNetPriceInputs = () => {
    return (
      <>
        {priceList && Object.keys(priceList).length > 0 ? 
          priceList.attributes.prices.map((data, i) => {
            let { name, idListaPrecio, value } = data
            return (
              <div
                className='col-6'
                key={i}
              >
                <NetPriceInput
                  name={name}
                  idListaPrecio={idListaPrecio}
                  value={parseInt(value)}
                  enabled={enabled}
                  standarCost={standarCost}
                  oldValue={value}
                  cost={cost}
                  resetValueInput={resetValueInput}
                  setResetValue={setResetValue}
                  save={save}
                  setSave={setSave}
                />
                <DiscountInput
                  enabled={enabled}
                  data={data}
                  resetValueInput={resetValueInput}
                  setResetValue={setResetValue}
                />
              </div>
            )
          })
          :
          null
        }
      </>
    )
  }

  const renderContent = () => {
    let costValidate = cost.length !== 0 && cost !== 0 && cost !== null && cost !== undefined
    let costZero = Math.round(parseInt(cost)*1.1)
    let withCostUp = Math.round(parseInt(standarCost)*1.1)
    return (
      <form className='container listprice Product-show-listPrice was-validated mt-2'>
        <Link
          to={`/product/${id}`}
          className='btn btn-primary'
        >
          <i className='fa fa-arrow-left mr-3'></i>
          Volver a la ficha
        </Link>

        <ModalCheck
          modalcheck={modalCheck}
          idTable='modaltablecheckproducts'
          data={modalCheckData}
          changeStateModalChech={changeStateModalCheck}
          sku={id}
          id_btn_edit='btn_edit_listprice'
          getProductData={getAllPriceList}
        />
        <div className='row'>
          <TitleButtonPriceListGeneral
            disabled={enabled}
            id_div_edit='div_edit_listprice'
            id_btn_edit='btn_edit_listprice'
            title='LISTADO DE PRECIOS'
            id_div_save='div_send_listprice'
            inputEnabler={inputEnabler}
            check_data={checkData}
            title_edit='Lista'
            description={`El precio debe ser mayor a ${ costValidate ? costZero : withCostUp }`}
            resetValue={resetValue}
          />
          {renderNetPriceInputs()}
        </div>
      </form>
    )
  }

  return (
    <>
      {renderSuccess ? 
        renderContent()
        :
        <h2>Cargando....</h2>
      }
    </>
  )

}

export default PriceList