import React from 'react';
import Required from './Required.js'
import Switch from 'react-switch';

class SwitchRadioButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
    this.handleChange = this.handleChange.bind(this)
  }
  
  componentWillReceiveProps(props){
    this.setState({ checked: props.value });
  }

  componentDidMount(){
    this.setState({ checked: this.props.value });
  }

  handleChange(checked) {
    this.setState({ checked: checked });
  }

  render() {
    return(
      <div className='row Product-show-attributes mb-3'>
        <div className='col-md-4'>
          <p className='mb-0' data-toggle='tooltip' data-placement='right' title={this.props.description}>
            {this.props.title}
            <Required required={this.props.required} />
          </p>
          <small className='text-secondary'>
            {this.props.flexline_name}
          </small>
        </div>
        <div className='col-md-8'>
          <Switch onChange={this.handleChange} checked={this.state.checked} onColor='#544f92' disabled={this.props.disabled} id={this.props.id} 
          name={this.props.name}/>
        </div>
      </div>
    );
  }
}
export default SwitchRadioButton;