import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import RecipeTable from '../../components/RecipeTable';
import TitleButton from '../../components/TitleButtonEdit';
import ModalCheck from '../modal_check/ModalCheck';
import es from '../../../es.json'
import 'react-toastify/dist/ReactToastify.css';

function Recipe(props){
  
  const[currentRole, setCurrentRole] = useState("")
  const[enabled, setEnabled] = useState(true);
  const[modalcheck, setModalCheck] = useState(false)
  const[newTableRowsPrincipal, setNewTableRowsPrincipal] = useState([])
  const[newTableRowsAlternative, setNewTableRowsAlternative] = useState([])
  const[oldTableRowsPrincipal, setOldTableRowsPrincipal] = useState([])
  const[oldTableRowsAlternative, setOldTableRowsAlternative] = useState([])
  const[formKind, setFormKind] = useState(props.formKind)

  useEffect(() => {
    setCurrentRole(localStorage.getItem('current_role'))
  }, [])

  useEffect(() => {
    if(oldTableRowsPrincipal.length === 0){
      setOldTableRowsPrincipal(Array.from(props.tableRowsPrincipal))
    }
    setNewTableRowsPrincipal(Array.from(props.tableRowsPrincipal))
  }, [props.tableRowsPrincipal])

  useEffect(() => {
    if(oldTableRowsAlternative.length == 0){
      setOldTableRowsAlternative(Array.from(props.tableRowsAlternative))
    }
    setNewTableRowsAlternative(Array.from(props.tableRowsAlternative))
  }, [props.tableRowsAlternative])

  useEffect(() => {
  }, [formKind])

  const inputEnabler = () =>{
    setEnabled(!enabled)
    setFormKind(enabled ? 'edit' : 'show')
    props.getProductData(props.sku);
  }

  const setTableRows = (rows, recipe, action) => {
    if(action == 'add'){
      if(recipe == 'principal_recipe') { setNewTableRowsPrincipal([...newTableRowsPrincipal, rows]) }
      else { setNewTableRowsAlternative([...newTableRowsAlternative, rows]) }
    }
    else{
      if(recipe == 'principal_recipe') { setNewTableRowsPrincipal(Array.from(rows)) }
      else { setNewTableRowsAlternative(Array.from(rows)) }
    }
  }

  const changeStateModalCheck = () =>{
    setModalCheck(!modalcheck)
  }

  const checkRole = (role) => {
    return role == currentRole
  }

  const setData = () => {
    let recipesKind = ['principal_recipe', 'alternative_recipe']
    let recipesValue = {
      principal_recipe_old: oldTableRowsPrincipal,
      principal_recipe_new: newTableRowsPrincipal,
      alternative_recipe_old: oldTableRowsAlternative,
      alternative_recipe_new: newTableRowsAlternative
    }
    
    let array = []
    recipesKind.forEach((recipeKind) => {
      let nextState = {}
      let nextAttributes = {}
      nextState['type'] = 'product_change'
      nextState['id'] = props.sku
      nextAttributes['sku'] = props.sku
      nextAttributes['field_name'] = recipeKind
      nextAttributes['field_detail'] = ''
      nextAttributes['old_value'] = recipesValue[`${recipeKind}_old`]
      nextAttributes['new_value'] = recipesValue[`${recipeKind}_new`]
      nextAttributes['title'] = es['RecipeTable'][recipeKind]
      nextAttributes['updated_by'] = localStorage.getItem('email');
      nextState['attributes'] = nextAttributes
      array.push(nextState)
    })

    return array
  }

  const check_data = () =>{
    let _ = require('lodash')
    if(newTableRowsPrincipal.length == 0){
      toast.error('No puede dejar la receta principal vacia.')
    }
    else if(_.isEqual(oldTableRowsPrincipal, newTableRowsPrincipal) && _.isEqual(oldTableRowsAlternative, newTableRowsAlternative)){
      toast.warn('No se ha realizado ningun cambio')
    }
    else{
      changeStateModalCheck()
    }
  }

  let roleClass = (checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? '' : 'd-none'
  let formClass = (props.productKind == 'kit' || props.productKind == 'pack') ? 'block' : 'd-none'

  return(
    <form className={`container recipe product-show-recipe was-validated mb-4 ${formClass}`}>
      <ModalCheck modalcheck={modalcheck} idTable='modaltablecheckrecipe' data={ setData() } 
      changeStateModalChech={ changeStateModalCheck } sku={ props.sku } id_btn_edit='btn_edit_recipe' getProductData={props.getProductData}/>
      <div className='row' id='recipe_form' name='recipe_form'>
        <div className="col-md-12">
          <TitleButton disabled={enabled} id_div_edit='div_edit_recipe' id_btn_edit='btn_edit_recipe' 
          title='RECETA' id_div_save='div_send_recipe' inputEnabler={ inputEnabler } roleVerification={ roleClass }
          check_data={ check_data } title_edit='Receta'/>
        </div>
        <div className="col-md-10">
          <RecipeTable
            name='principal_recipe'
            tableRows= { props.tableRowsPrincipal }
            setTableRows= { setTableRows }
            formKind= { formKind }
            enabled= { enabled }
          />
        </div>
        <div className="col-md-10">
          <RecipeTable
            name='alternative_recipe'
            tableRows= { props.tableRowsAlternative }
            setTableRows= { setTableRows }
            formKind= { formKind }
            enabled= { enabled }
          />
        </div>
      </div>
    </form>
  )
}

export default Recipe;
