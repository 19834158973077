// import items from '../../data/items';
import requiredState from '../../api/stateRequired.json'

const defaultState = requiredState['EXISTENCIA']

function reducer(state = defaultState, {type, typeSelected}) {
  let hinges = {}
  switch (type) {
    case "CHANGE_REQUIRED": {
      hinges = requiredState[typeSelected]
      // hinges = changeRequired
      break;
    }
    case "PRODUCT_LOADED": {
      hinges = requiredState[typeSelected]
      // hinges = changeRequired
      break;
    }
    case "RESET_REUIRED":
    default:
      hinges = state
      break;
  }
  return hinges
}

export default reducer;
