import React from 'react'
import { publishInSegment } from '../../api/APIPost'
import { toast } from 'react-toastify';
import es from '../../es.json'

function PublishSegment(props){

  // Publish Product
  // Retrieves all the checkboxes, gets the clicked one and call de PostPublish method to
  // send the request to publish the product in the selected segment
  // @params event [Event] the event that triggered the function
  // 
  // @returns [Void] call the PostPublish method.
  const publishProduct = (event) => {
    if(props.currentRole == 'basic'){return}
    const sku = document.getElementById('input_sku').value
    const publish_segments = Array.from(document.getElementsByClassName('radio-publish'))
    
    publish_segments.forEach( segment => {
      if(!event.target.checked) {
        event.target.checked = false
      }
      else {
        segment.id === event.target.id ? segment.checked = true : segment.checked = false
      }
      segment.disabled = true
    })

    postPublish(sku, event.target.id, event.target.checked, publish_segments)
  }

  // Build Payload
  // Builds the payload in JSON API format to send to the API
  // @params segment [String] the segment to publish the product in
  // @params action [String] if the product is going to be published or unpublished
  //
  // @returns [Hash] the payload in JSON API format
  const buildPayload = (segment, action) => {
    const publish_action = action ? 'publish' : 'unpublish'
    const publish_segment = action ? segment : []
    return { type: 'PUBLISH_SEGMENT', attributes: { segment: publish_segment, publish_action: publish_action } }
  }

  // Post Publish
  // Calls the publishInSegment method from the APIPost file to send the request to publish the product
  // in the selected segment
  // On response, it shows a toast message and enables the checkboxes again
  // @params segment_id [String] the segment to publish
  // @params action [String] if the product is going to be published or unpublished
  // @params publish_segments [Array] the array of checkboxes
  // 
  // @returns [Void] shows a toast message and enables the checkboxes again
  const postPublish = (sku, segment_id, segment_action, publish_segments) => {
    publishInSegment(sku, buildPayload(segment_id, segment_action)).then(response => {
      publish_segments.map( segment => segment.disabled = false)
      if(response.data.attributes.aasm_state == 'waiting_approval'){
        return toast.info(es['Publish']['waiting_aproval'])
      }
      else{
        return toast.success(es['Publish']['success'])
      }
    }).catch( err => {
      toast.error(es['Error']['no_response']);
    });
  }

  // Render Segment
  // Creates a checkbox with the segment information
  // @params segment [Hash] the segment information
  //
  // @returns [HTML] the checkbox with the segment information
  const renderSegment = (segment) =>{
    return(
      <li className = 'mx-2'>
        <input
          type = "checkbox"
          id = { segment['id'] }
          className = 'radio-publish'
          value = { segment['type'] }
          disabled={props.currentRole == 'basic'}
          onClick = {(event) => { publishProduct(event) }}
        />
        <label
          id = { `${segment['id']}-text` }
          htmlFor = { segment['id'] }
        >
          { segment['type'].toUpperCase() }
        </label>
      </li>
    )
  }

  // Iterates segments to create the HTML checkbox and stores it in array
  const segments = []
  props.segments.forEach(segment => {
    segments.push(renderSegment(segment))
  })
  // Render array of checkboxes
  return (
    segments
  )
}

export default PublishSegment
