import React, { useEffect } from "react";
import Title from '../components/Title';
import RecipeTable from '../components/RecipeTable';

function Recipe(props){
  
  useEffect(() => {
    recipeAvailable()
  }, [props.selectedKindState])

  const recipeAvailable = () => {
    let recipeForm = document.getElementById('recipe_form')
    if(props.selectedKindState == 'simple'){ recipeForm.classList.add('d-none') }
    else { recipeForm.classList.remove('d-none') }
  }

  return(
    <div className='row' id='recipe_form' name='recipe_form'>
      <div className='col-md-12'>
        <Title title='RECETA'/>
      </div>
      <div className="col-md-6">
        <RecipeTable 
          name='principal_recipe'
          tableRows= { props.tableRowsPrincipal }
          setTableRows= { props.setTableRows }
          formKind= { props.formKind }
        />
      </div>
      <div className="col-md-6">
        <RecipeTable 
          name='alternative_recipe'
          tableRows= { props.tableRowsAlternative }
          setTableRows= { props.setTableRows }
          formKind= { props.formKind }
        />
      </div>
    </div>
  );

}

export default Recipe;
