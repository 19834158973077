import React, { useState, useEffect } from 'react';
import InputTextAttributeFull from './InputTextAttributeFull';
import TitleButton from '../../components/TitleButtonEdit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalCheck from '../modal_check/ModalCheck';
import es from "../../../es.json"

function Name(props){

  const[enabled, setEnabled] = useState(true);
  const[modalcheck, setModalcheck] = useState(false);
  const[modalcheckData, setModalcheckData] = useState([]);
  const[sku, setSku] = useState('');
  const[currentRole, setCurrentRole] = useState("");

  useEffect(() => {
    setSku(props.sku)
    setCurrentRole(localStorage.getItem('current_role'))
  }, [])

  const checkRole = (role) => {
    return role == currentRole
  }

  const inputEnabler = () =>{
    setEnabled(!enabled)
  }

  useEffect(() => {
    setEnabled(true)
  }, [props])

  const check_data = () =>{
    let array = [];
    if(document.getElementById('input_sku')){
      setSku(document.getElementById('input_sku').value);
    }
    
    document.querySelectorAll('.name input[type=text]').forEach((select) => {
      let name = select.getAttribute('id');
      let value = select.value.toUpperCase().trim();

      if(value !== props[name]){
        let nextState = {};
        let nextName = {};

        nextState['type'] = 'product_change';
        nextState['id'] = sku;
        nextName['sku'] = sku;
        nextName['field_name'] = name;
        nextName['field_detail'] = '';
        nextName['old_value'] = props[name];
        nextName['new_value'] = value;
        nextName['title'] = select.getAttribute('placeholder');
        nextName['updated_by'] = localStorage.getItem('email');

        nextState['attributes'] = nextName
        array.push(nextState);
      }
    });

    if(array.length!==0){
      setModalcheck(!modalcheck)
      setModalcheckData(array)
      setSku(sku)
    }else{
      toast.warn(es['Common']['no_change']);
    }
  }

  const changeStateModalChech = () =>{
    setModalcheck(!modalcheck)
  }

  let roleClass =  (checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? '' : 'd-none'

  return(
    <form className='name product-show-name was-validated mb-4'>
      <ModalCheck modalcheck={modalcheck} idTable='modaltablecheckproducts' data={modalcheckData} 
      changeStateModalChech={changeStateModalChech} sku={sku} id_btn_edit='btn_edit_custom_name' getProductData={props.getProductData}/>
      
        <TitleButton disabled={enabled} id_div_edit='div_edit_name' id_btn_edit='btn_edit_custom_name' 
        title={ es['Common']['name'].toUpperCase() } id_div_save='div_send_name' inputEnabler={inputEnabler} roleVerification={roleClass}
        check_data={check_data} title_edit={ es['Common']['name'] }/>
      <div className='row'>  
        <div className='col-md-10'>
          <InputTextAttributeFull title={es['Common']['name']} name='name' id='name' disabled={enabled} 
          calculatePercentOfInput={props.calculatePercentOfInput}
          value_gloss={props.gloss} 
          value_name={props.name}
          required={true}
          />
        </div>
      </div>
    </form>
  );
}
export default Name;
