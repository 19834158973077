import React from 'react';
import Required from '../../components/Required.js'

class InputTextAttribute extends React.Component {
  // Constructor
  // 
  // Makes variables incoming in props global inside class scope
  // @returns nil 
  constructor(props) {
    super(props);
  }

  render() {
    let {title, required, flexline_name, name, id, disabled, readonly, hidden, maxlength, skuAuto, measurement, checked} = this.props
    
    return(
      <div className='row Product-show-InputTextAttribute mb-2 col-12'>
        <div class="form-check d-flex attributes-form pl-0">
          <input type='checkbox' name={name} className={`${this.props.type != 'checkbox' ? 'form-control' : ''} text-uppercase mt-1`} id={id} 
            placeholder={title} disabled={disabled} onKeyUp={this.getValue} required={required}
            readOnly={readonly} hidden={hidden} maxLength={maxlength} skuauto={skuAuto} onClick={this.props.onClick}
            step={measurement? '.01' : ''} checked={checked}/>
          <label className='ml-3 mb-0 labelb-0' for={id}>
              {title}
              <Required required={required} />
          </label>
          <small className='text-secondary'>
            {flexline_name}
          </small>
      </div>
    </div>
    );
  }
}
export default InputTextAttribute;
