import React, { useEffect } from "react";
import ReactTooltip from 'react-tooltip';
import Published from "../../show/published/Published";
import es from "../../../es.json"

const Title = ({sku, barPercentage, status, publish_segment, action, type}) => {
  useEffect(() => {
    // When the function is executed, the content will be rendered.
  }, [])

  return (
    <div className="col-12 product-show-name" name='form_change_name'>
      <div className="container">
        <div className='row mt-5'>
          <div className='col-md-6'>
            <h4 id='name_product_h2'>{sku}</h4>
            { action == 'create' ? '' :
              <Published
              barPercentage= { barPercentage }
              status= { status }
              publish_segment= { publish_segment }
            />
            }
          </div>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-12 text-right'>
                <label>{ es['Components']['Name']['completed_file'] }</label>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8 offset-4'>
                <div className='progress dashboard-persent text-center'>
                  <div id='percent-bar' className='progress-bar bg-progress' role='progressbar' aria-valuemin='0' aria-valuemax='100'></div>
                  <label id='number-percent-bar' className='pr-3 justify-content-center d-flex position-absolute w-100 text-progressbar font-weight-bold'></label>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 text-right'>
                <label id='number-incomplete-input' className='mt-1' data-tip='' />
                <ReactTooltip place="top" type="dark" effect="float"/>
              </div>
            </div>
            <div className='row'>
              <div className="d-none col-md-8">
                <label id='show-sku' className='text-primary'></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Title;