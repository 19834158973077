import React from 'react';
import PendingChange from './pending_change/PendingChange';
import HistoryChange from './history_change/HistoryChange';
import { Route } from 'react-router-dom';

// import { data } from './data.json'
// import './Index.css';

class Index extends React.Component {

  constructor() {
    super();
    this.state = {
    }
  }

  render() {
    return(
      <div className='Product-index mt-2'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <h2 className='ml-2'>HISTORIAL DE CAMBIOS</h2>
            </div>
          </div>
          <Route exact path='/productchanges/' component={PendingChange}/>
          <Route path='/productchanges/all' component={HistoryChange}/>
        </div>
      </div>
    );
  }
}

export default Index;
