import React, { useState, useEffect } from 'react';
import InputTextAttributeFull from '../../components/InputTextAttributeFull';
import TitleButton from '../../components/TitleButtonEdit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalCheck from '../modal_check/ModalCheck';

function Name(props){

  const[enabled, setEnabled] = useState(true);
  const[modalcheck, setModalcheck] = useState(false);
  const[modalcheckData, setModalcheckData] = useState([]);
  const[sku, setSku] = useState('');
  const[currentRole, setCurrentRole] = useState("");

  useEffect(() => {
    setSku(props.sku)
    setCurrentRole(localStorage.getItem('current_role'))
    
  }, [])

  const checkRole = (role) => {
    return role == currentRole
  }

  const inputEnabler = () =>{
    // if(this.state === undefined)
    setEnabled(!enabled)
  }

  useEffect(() => {
    setEnabled(true)
  }, [props])

  const check_data = () =>{
    let array = [];
    setSku(document.getElementById('input_sku').value);

    document.querySelectorAll('.name input[type=text]').forEach((select) => {
      let name = select.getAttribute('name');
      let value = select.value.toUpperCase().trim();

      if(value !== props[name]){
        let nextState = {};
        let nextName = {};

        nextState['type'] = 'product_change';
        nextState['id'] = sku;
        nextName['sku'] = sku;
        nextName['field_name'] = name;
        nextName['field_detail'] = '';
        nextName['old_value'] = props[name];
        nextName['new_value'] = value;
        nextName['title'] = select.getAttribute('placeholder');
        nextName['updated_by'] = localStorage.getItem('email');

        nextState['attributes'] = nextName
        array.push(nextState);
      }
    });

    if(array.length!==0){
      setModalcheck(!modalcheck)
      setModalcheckData(array)
      setSku(sku)
    }else{
      toast.warn('No se ha realizado ningun cambio');
    }
    // send_data(array, sku);
  }

  const changeStateModalChech = () =>{
    setModalcheck(!modalcheck)
  }

  let roleClass =  (checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? '' : 'd-none'

  return(
    <form className='container name product-show-name was-validated mb-4'>
      <ModalCheck modalcheck={modalcheck} idTable='modaltablecheckproducts' data={modalcheckData} 
      changeStateModalChech={changeStateModalChech} sku={sku} id_btn_edit='btn_edit_name' getProductData={props.getProductData}/>
      
      <TitleButton disabled={enabled} id_div_edit='div_edit_name' id_btn_edit='btn_edit_name' 
      title='NOMBRE' id_div_save='div_send_name' inputEnabler={inputEnabler} roleVerification={roleClass}
      check_data={check_data} title_edit='Nombre'/>
      <div className='row'>  
        <div className='col-md-10'>
          <InputTextAttributeFull title='Nombre' name='name' id='name' disabled={enabled} 
          value_gloss={props.gloss} 
          value_name={props.name} 
          maxlength='160'
          name_second = 'gloss'
          id_second = 'gloss'
          title_second = 'Glosa'
          maxlength_second = '100'
          required={true}
          description_name='Detalle del producto'
          description_gloss='Detalle que aparece en la facturación y Notas de venta'
          description_print='Detalle que aparece al imprimir'
          calculatePercentOfInput = {props.calculatePercentOfInput}
          flexline_name=''
          flexline_gloss='glosa'
          />
        </div>
      </div>
    </form>
  );
}
export default Name;
